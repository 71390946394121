/**
 * Componente Templates que permite llevar acabo todas las operaciones de plantillas
 */

import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Divider, Table, notification, Popconfirm } from 'antd';

import { folders as foldersAction } from 'actions';
import { DrawerTemplate } from 'components/folders/drawer_template';

/**
 * Exporta el componente Templates
 */
export const Templates = () => {

  // Funcion que permite ejecutar todas las acciones del estado de redux
  const dispatch = useDispatch();
  // Selecciona los reductores
  const roles = useSelector(state => state.get('roles').get('roles').roles);

  // Manejo de los estados del componente
  const [docsFolders, setDocsFolders] = useState();
  const [templates, setTemplates] = useState({ loading: false, source: [] });
  const [drawer, setDrawer] = useState({ isupdate: false, visible: false, loading: false });
  const [folders, setFolders] = useState({ visible: false, loading: false, source: [] });
  const [drawerFolder, setDrawerFolder] = useState({ isupdate: false, visible: false, loading: false });
  const [row, setRow] = useState({ key: [], data: [] });
  const [itemTemplate, setItemTemplate] = useState({ name: "", description: "" });

  /**
   * Metodo que permite cargar el listado de plantillas
   */
  const loadTemplates = useCallback(() => {
    setTemplates({ loading: true, source: [] });
    dispatch( foldersAction.templates.read({ roles: roles.folders.templates }) ).then( templates => {
      setTemplates({
        loading: false,
        source: templates.template.data() ? templates.template.data().templates.map( ( tpl, index ) => ({
          key: index,
          name: tpl.name,
          description: tpl.description,
          path: tpl.reference,
          reference: templates.template
        })) : []
      });
    }).catch( error => {
      setTemplates({ loading: false, source: [] });
      notification.error({
        message: 'Error',
        description: "Se presento un error al tratar de cargar las plantillas",
        placement: 'topLeft'
      });
    });
  }, [dispatch, roles.folders.templates]);

  /**
   * Metodo que permite eliminar una plantilla
   * @param {object} template 
   */
  const deleteTemplate = tpl => {
    setTemplates({ loading: true, source: [] });
    dispatch(
      foldersAction.templates.delete({
        roles: roles.folders.templates,
        template: tpl
      })
    ).then(() => loadTemplates() ).catch( error => {
      setTemplates({ loading: false, source: [] });
      notification.error({
        message: 'Error',
        description: "Se presento un error al tratar de eliminar la plantilla",
        placement: 'topLeft'
      });
    });
  };

  /**
   * Metodo que permite cargar la plantilla seleccionada
   */
  const loadSingleTemplate = item => {

    setItemTemplate({ name: item.name, description: item.description });
    setDocsFolders(item.reference.ref.collection( item.name ).doc('documents'));
    setDrawer({ isupdate: true, visible: true, loading: false });
    setFolders({ loading: true, source: [] });
    loadFolders({ doc: item.reference.ref.collection( item.name ).doc('documents'), folders: [] }).then( source => {
      setFolders({ loading: false, source });
    }).catch( error => {
      notification.error({
        message: 'Error',
        description: "Se presento un error al tratar de cargar las carpetas",
        placement: 'topLeft'
      });
    });

  };

  /**
   * Metodo que permite cargar la estructura de capetas
   */
  const loadFolders = ({ doc, folders }) => {
    return new Promise( async ( resolve, reject ) => {
      try {
        
        const fields = await doc.get();
        if ( fields.exists ) {

          const data = fields.data();
          for ( const folder of data.folders ) {

            folders.push({
              key: folder.reference.id,
              name: folder.name,
              description: folder.description,
              isUpload: folder.isUpload,
              reference: folder.reference.path.collection( folder.reference.id ),
              children: []
            });

            const indexFolder = folders.findIndex( item => item.key === folder.reference.id );
            const childs = await doc.collection( folder.reference.id ).get();
            
            for ( const doc of childs.docs ) {
              // eslint-disable-next-line no-unused-expressions
              (await loadFolders({ doc: doc.ref, folders: [] }))?.forEach( data => folders[ indexFolder ].children.push( data ) );
            }

          } resolve( folders );

        } else resolve( folders );

      } catch ( error ) { reject(error); }
    });
  };

  // Ejecuta la funcion una vez termina de renderizar el componente
  useEffect(() => { loadTemplates(); }, [ loadTemplates ]);

  /**
   * Metodo que renderiza el componente
   */
  return (
    <>

      <Table
        bordered = { false }
        size = 'middle'
        loading = { templates.loading }
        locale = {{ emptyText: 'No se han creado plantillas' }}
        dataSource = { templates.source }
        columns = {[
          { title: 'Plantilla', dataIndex: 'name', key: 'name' },
          { title: 'Descripción', dataIndex: 'description', key: 'description' },
          { title: 'Acciones', key: 'actions', render: ( text, record ) => (
            <span>

              { roles?.folders?.templates?.update ?
                  <>
                    <Button type = "link" onClick = { () => loadSingleTemplate( record ) } > Editar </Button>
                    <Divider type = "vertical" />
                  </> : null }

              { roles?.folders?.templates?.delete ?
                  <Popconfirm
                    title = "Esta seguro de eliminar la plantilla?"
                    okText = "Eliminar"
                    cancelText = "Cancelar"
                    onConfirm = { () => deleteTemplate( record ) } >
                    <Button type = "link" > Eliminar </Button>
                  </Popconfirm> : null }

            </span>
          )}
        ]}
        title = { () => (
          <>
            { roles?.folders?.templates?.create ?
                <Button type = 'dashed'
                  onClick = { () => {
                    setDrawer({ isupdate: false, visible: true, loading: false });
                    setFolders({ loading: false, source: [] });
                  }} 
                > Nueva plantilla </Button> : null }
          </>
        )}
      />

      <DrawerTemplate
        docsFolders = { docsFolders }
        setDocsFolders = { setDocsFolders }
        setDrawerFolder = { setDrawerFolder }
        drawer = { drawer }
        folders = { folders }
        row = { row}
        roles = { roles }
        setRow = { setRow }
        loadTemplates = { loadTemplates }
        setDrawer = { setDrawer }
        setFolders = { setFolders }
        loadFolders = { loadFolders }
        itemTemplate = { itemTemplate }
        drawerFolder = { drawerFolder } />

    </>
  );
};