import React, { useState } from 'react';
import { Input, Button, Modal, Form, DatePicker } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useDocuments } from 'components/documents/context';

export const useModalSearchFile = () => {
    // Manejo de los estados del componente
    const [find, setFind] = useState({ visible: false, loading: false });
    return {
        search: { ...find, set: setFind },
    }
};

export const ModalSearchFile = () => {
    
    // Hook para el control de los formularios
    const [form] = Form.useForm();

    const { folders, search } = useDocuments();
    const { current } = folders;

    const onSearch = values => {

        let { set, source, ...rest } = folders.list;
        search.set({ visible: true, loading: true });

        source = values?.file?.trim()?.length > 0
            ? source.filter(file => file.data.name === values.file)
            : source;
        
        source = values?.date
            ? source.filter(file => moment(file.data.date_of_upload.toDate()).isBetween(values.date, values.date, 'date', '[]')
            ) : source;

        set({ source, ...rest });
        form.resetFields();
        search.set({ visible: false, loading: false });

    };

    const todosHandle = () => {
        form.resetFields();
        folders.load( folders.routes.data[folders.routes.data.length -1]?.reference ).then( () => {
            search.set({ visible: false, loading: false });
            current.isUpload.set(true);
        });
    };

    const onSubmit = () => form.submit();
    const onCancel = () => search.set({ visible: false, loading: false });


    return search.visible ? (
        <Modal
            forceRender
            visible = { search.visible }
            confirmLoading = { search.loading }
            title = 'Buscar archivos' 
            maskClosable = { false }
            closable = { false }
            footer = {[
                <Button style = {{ marginRight: 10 }} key = "cancel" onClick = { onCancel } > Cancelar </Button>,
                <Button.Group key = 'group' >
                <Button type = "primary" key = "clean" onClick = { todosHandle } > Todos </Button>
                <Button type = "primary" key = "search" onClick = { onSubmit } > Filtrar </Button>
                </Button.Group>
            ]} >
            <Form layout = "vertical" form = { form } onFinish = { onSearch } >
                <Form.Item name = "file" label = "Archivo" >
                    <Input
                        placeholder = "Nombre del archivo"
                        prefix = { <FileOutlined style = {{ color: 'rgba(0,0,0,.25)' }} /> } />
                    </Form.Item>
                    <Form.Item name = "date" label = "Fecha" >
                    <DatePicker  style={{ width: '65%', marginLeft: '3%' }} />
                </Form.Item>
            </Form>
        </Modal>
    ) : false
}