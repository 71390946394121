/**
 * Componente Folders que permite renderizar la pantalla incial de carpetas
 */

import React from 'react';
import { Tabs } from 'antd';
import { PicLeftOutlined  } from '@ant-design/icons';

import { Templates } from 'components/folders/templates';

/**
 * Exporta el componente Folders
 */
export const Folders = () => {
  
  /**
   * Metodo que renderiza el componente
   */
  return (
    <div>
      <div style = {{ marginBottom: 15 }} >
        <Tabs defaultActiveKey = "1" >
          <Tabs.TabPane tab = { <span> <PicLeftOutlined /> Plantillas </span> } key = "1" >
            <Templates />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
}