import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, Drawer, Input, Divider, Table, Checkbox, Modal, notification } from 'antd';

import { folders as foldersAction, documents as documentsAction } from 'actions';
import { DrawerFolder } from 'components/folders/drawer_folder';

export const DrawerTemplate = ({
    docsFolders,
    setDocsFolders,
    setDrawerFolder,
    drawer,
    folders,
    row,
    setRow,
    roles,
    loadTemplates,
    setDrawer,
    setFolders,
    loadFolders,
    itemTemplate,
    drawerFolder }) => {

    // Funcion que permite ejecutar todas las acciones del estado de redux
    const dispatch = useDispatch();
    // Manejo de los estados del componente
    const [folderFields, setFolderFields] = useState({ name: "", description: "", isUpload: false });
    const [templatesFields, setTemplatesFields] = useState({ name: "", description: "" });
    // Hook para el control de los formularios
    const [form] = Form.useForm();

    /**
    * Metodo que se ejecuta cuando se abre la ventana emergente de creación de carpetas en las plantillas
   */
    const openDrawerFolder = () => {
        form.validateFields().then(() => {
            setFolderFields({ name: "", description: "", isUpload: false });
            setDrawerFolder({ isupdate: false, visible: true, loading: false });
        }).catch();
    };

    /**
     * Metodo que se ejecuta cuando se abre la venta emergente de edición de carpetas en las plantillas
     */
    const openEditDrawerFolder = () => {
        form.validateFields().then(() => {
            setFolderFields({
                name: row?.data[0]?.name,
                description: row?.data[0]?.description,
                isUpload: row?.data[0]?.isUpload
            });
            setDrawerFolder({ isupdate: true, visible: true, loading: false });
        }).catch();
    };

    /**
   * Metodo que se ejecuta cuando se cierra la venta emergente de plantillas
   */
    const closeDrawer = () => {
        form.resetFields();
        loadTemplates();
        setDrawer({ isupdate: false, visible: false, loading: false });
    };

    /**
     * Metodo que permite guardar una plantilla
     */
    const saveTemplate = values => {

        setDrawer({ isupdate: drawer.isupdate, visible: true, loading: true });
        dispatch(
            foldersAction.templates.create({
                roles: roles.folders.templates,
                template: {
                    name: values.name,
                    description: values.description
                }
            })
        ).then( () => {

            setDrawer({ isupdate: false, visible: false, loading: false });
            form.resetFields();
            loadTemplates();

        }).catch( error => {

            setDrawer({ isupdate: drawer.isupdate, visible: false, loading: false });
            notification.error({
                message: 'Error',
                description: "Se presento un error al tratar de guardar la plantilla",
                placement: 'topLeft'
            });

        })

    };

    /**
     * Metodo que permite actualizar una plantilla
     * @param {object} evt 
     */
    const updateTemplate = values => {

        setDrawer({ isupdate: drawer.isupdate, visible: true, loading: true });
        dispatch(
            foldersAction.templates.update({
                roles: roles.folders.templates,
                template: {
                name: values.name,
                description: values.description
                }
            })
        ).then( () => {

            setDrawer({ isupdate: false, visible: false, loading: false });
            form.resetFields();
            loadTemplates();

        }).catch( error => {

            setDrawer({ isupdate: drawer.isupdate, visible: false, loading: false });
            notification.error({
                message: 'Error',
                description: "Se presento un error al tratar de guardar la plantilla",
                placement: 'topLeft'
            });

        })

    };

    /**
   * Metodo que permite eliminar una carpeta de la plantilla
   * @param {object} folder 
   */
    const deleteFolder = folder => {
        setFolders({ loading: true, source: [] });
        setRow({ key: [], data: [] });
        dispatch( documentsAction.delete({
            roles: roles.folders.templates,
            item: {
            name: folder.name,
            reference: {
                path: folder.reference.parent,
                id: folder.reference.id
            }
        }})).then( () => loadFolders({ doc: docsFolders, folders: [] }) ).then( source => setFolders({ loading: false, source }) ).catch( error => {
            notification.error({
                message: 'Error',
                description: 'Se presento un error al tratar de eliminar la carpeta',
                placement:  'topRight'
            });
        });
    };

    useEffect(() => {
        setTemplatesFields({ name: itemTemplate.name, description: itemTemplate.description });
        form.setFields([
            { name: "name", value: itemTemplate.name },
            { name: "description", value: itemTemplate.description }
          ]);
    }, [form, itemTemplate]);

    return (
        <Drawer
            forceRender
            title = { `${ drawer.isupdate ? "Editar" : "Nueva" } Plantilla` }
            width = { 720 }
            placement = "left"
            onClose = { closeDrawer }
            maskClosable = { false }
            visible = { drawer.visible }
            style = {{
                height: 'calc(100% - 55px)',
                overflow: 'auto',
                paddingBottom: 53,
            }} >

            <Form form = { form } layout = "vertical"
                onFinish = { drawer.isupdate ? updateTemplate : saveTemplate }
                onValuesChange = {(_, { name, description }) => setTemplatesFields({ name, description }) } >
            <Form.Item name = "name" label = "Nombre de la plantilla:"
                rules = {[{ required: true, message: 'Debe ingresar el nombre de la plantilla' }]} >
                <Input disabled = { drawer.isupdate } />
            </Form.Item>
            <Form.Item name = "description" label = "Descripción:"
                rules = {[{ required: true, message: 'Debe ingresar la descripción de la plantilla' }]} >
                <Input />
            </Form.Item>
            </Form>

            <Divider />

            <Button
                loading = { folders.loading }
                onClick = { openDrawerFolder }
                type = "primary"
                style = {{ marginBottom: 16 }}
            > Agregar carpeta </Button>

            <Divider type = "vertical" />

            <Button
                loading = { folders.loading }
                onClick = { openEditDrawerFolder }
                disabled = { row.key.length <= 0 }
                type = "dashed"
                style = {{ marginBottom: 16 }}
            > Editar carpeta </Button>

            <Divider type = "vertical" />

            <Button
                loading = { folders.loading }
                onClick = { () => {
                    Modal.confirm({
                        title: `Eliminar carpeta ${ row.data[0].name }`,
                        content: 'Esta seguro de eliminar la carpeta seleccionada?',
                        okText: 'Eliminar',
                        okType: 'danger',
                        cancelText: 'Cancelar',
                        onOk: () => deleteFolder( row.data[0] )
                    })
                }}
                disabled = { row.key.length <= 0 }
                type = "danger"
                style = {{ marginBottom: 16 }}
            > Eliminar carpeta </Button>

            <Table
                bordered
                size = 'middle'
                loading = { folders.loading }
                locale = {{ emptyText: 'No se han agregado carpetas' }}
                columns = {[
                    { title: 'Nombre', dataIndex: 'name', key: 'name' },
                    { title: 'Descripción', dataIndex: 'description', key: 'description' },
                    { title: 'Permitir subir archivos?', key: 'isupload', render: ( text, record ) => (
                        <Checkbox defaultChecked = { record.isUpload } disabled />
                    )}
                ]}
                dataSource = { folders.source }
                pagination = {{ pageSize: 10 }}
                rowSelection = {{
                    selectedRowKeys: row.key,
                    onSelect: ( record, selected, selectedRows ) => {
                        setRow({ key: selected ? [ record.key ] : [], data: selected ? [ record ] : [] });
                    }
                }}
            />

            <DrawerFolder
                docsFolders = { docsFolders }
                setDocsFolders = { setDocsFolders }
                setDrawerFolder = { setDrawerFolder }
                drawerFolder = { drawerFolder }
                drawer = { drawer }
                row = { row }
                setRow = { setRow }
                roles = { roles }
                setFolders = { setFolders }
                loadFolders = { loadFolders }
                folderFields = { folderFields }
                templatesFields = { templatesFields } />

            <div style = {{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'right',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
            }} >
                <Button
                    style = {{ marginRight: 8 }}
                    loading = { drawer.loading }
                    onClick = { closeDrawer }
                > Cancelar </Button>
                <Button
                    type = "primary"
                    htmlType = "submit"
                    onClick = { () => form.submit() }
                    loading = { drawer.loading }
                > Guardar </Button>
            </div>

        </Drawer>
    )
};