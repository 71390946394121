/**
 * Acción de REDUX que permite llevar a cabo todas las operaciones sobre las referencias de los archivos 
 */

import { REFERENCES } from 'types';

/**
 * Exporta las funciones para el manejo de las referencias
 */
export const references = {

  /**
   * Función que permite crear una referencia.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * Información de la referencia
   */
  create: ({ roles, reference }) => ( dispatch, state, firebase ) => {

    dispatch({ type: REFERENCES.CREATE.CREATING_REFERENCE });
  
    return new Promise( ( resolve, reject ) => {
      if ( roles.create ) {
  
        const db = firebase().firestore();
        const uid = firebase().auth().currentUser.uid;
        const batch = db.batch();

        const log_audit = db.collection('audit').doc();
        const references_ref = db.collection('references').doc();

        const after = {
          "Nombre": reference.name,
          "Tipo de dato": reference.type
        };

        batch.set(log_audit, { uid, eventType: REFERENCES.CREATE.CREATE_REFERENCE, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
        batch.set(log_audit.collection('after').doc(), after);
        batch.set(references_ref, after);

        batch.commit().then(() => {
          resolve(
            dispatch({ type: REFERENCES.CREATE.CREATED_REFERENCE, reference: references_ref })
          )
        }).catch(
          error => reject(
            dispatch({ type: REFERENCES.CREATE.ERROR, code: error.code, message: error.message })
          )
        );

      } else reject( dispatch({ type: REFERENCES.CREATE.ERROR, code: 0, message: 'Permisos insuficientes para crear referencias' }) );
      
    })

  },
  
  /**
   * Función que permite consultar la lista de referencias.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   */
  read: ({ roles }) => ( dispatch, state, firebase ) => {
    
    dispatch({ type: REFERENCES.READ.READING_REFERENCE });
    
    return new Promise( ( resolve, reject ) => {
      // if ( roles.read ) {

        const db = firebase().firestore();
        const references = db.collection('references');

        references.get().then( query => {

          const res = query.docs.map( doc => {
            const data = doc.data();
            return ( Object.getOwnPropertyNames(data).length > 0 ) ? {
              id: doc.id,
              name: data['Nombre'],
              type: data['Tipo de dato']
            } : {};
          });

          resolve( dispatch({ type: REFERENCES.READ.READED_REFERENCE, reference: res }) );

        }).catch( error => reject( dispatch({ type: REFERENCES.READ.ERROR, code: error.code, message: error.message }) ))
    
      // } else reject( dispatch({ type: REFERENCES.READ.ERROR, code: 0, message: 'Permisos insuficientes para consultar la lista de referencias' }) )
      
    })
    
  },
  
  /**
   * Función que permite actualizar la lista de referencias.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * ID de la referencia
   * Información de la referencia
   */
  update: ({ roles, id, reference }) => ( dispatch, state, firebase ) => {
  
    dispatch({ type: REFERENCES.UPDATE.UPDATING_REFERENCE });
  
    return new Promise( async ( resolve, reject ) => {
      if ( roles.update ) {
    
        const db = firebase().firestore();
        const uid = firebase().auth().currentUser.uid;
        const batch = db.batch();

        const log_audit = db.collection('audit').doc();
        const ref = db.collection('references').doc( id );

        const before = (await ref.get()).data();
        const after = {
          "Nombre": reference.name,
          "Tipo de dato": reference.type
        };
        
        batch.set(log_audit, { uid, eventType: REFERENCES.UPDATE.UPDATE_REFERENCE, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
        batch.set(log_audit.collection('before').doc(), before);
        batch.set(log_audit.collection('after').doc(), after);
        batch.update(ref, after);

        batch.commit().then(() => {
          resolve(
            dispatch({ type: REFERENCES.UPDATE.UPDATED_REFERENCE, reference: id })
          )
        }).catch(
          error => reject(
            dispatch({ type: REFERENCES.UPDATE.ERROR, code: error.code, message: error.message })
          )
        );

      } else reject( dispatch({ type: REFERENCES.UPDATE.ERROR, code: 0, message: 'Permisos insuficientes para actualizar la lista de referencias' }) )
      
    });
    
  },

  /**
   * Función que permite eliminar una referencias.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * ID de la referencia
   */
  delete: ({ roles, id }) => ( dispatch, state, firebase ) => {
  
    dispatch({ type: REFERENCES.DELETE.DELETING_REFERENCE });
  
    return new Promise( async ( resolve, reject ) => {
      if ( roles.delete ) {

        const db = firebase().firestore();
        const uid = firebase().auth().currentUser.uid;
        const batch = db.batch();

        const log_audit = db.collection('audit').doc();
        const ref = db.collection('references').doc( id );

        const before = (await ref.get()).data();

        batch.set(log_audit, { uid, eventType: REFERENCES.DELETE.DELETE_REFERENCE, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
        batch.set(log_audit.collection('before').doc(), before);
        batch.delete(ref);

        batch.commit().then(() => {
          resolve(
            dispatch({ type: REFERENCES.DELETE.DELETED_REFERENCE, reference: id })
          )
        }).catch(
          error => reject(
            dispatch({ type: REFERENCES.DELETE.ERROR, code: error.code, message: error.message })
          )
        );

      } else reject( dispatch({ type: REFERENCES.DELETE.ERROR, code: 0, message: 'Permisos insuficientes para eliminar la lista de referencias' }) )
      
    })

  }

}