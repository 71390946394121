/**
 * Componente Login que permite llevar a cabo todas las operación de Login de la aplicación
 */

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Button, Checkbox, notification } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { AUTH } from 'types';
import { email } from 'actions';
import { ModalRecoveryPassword } from 'components/login/modal_recovery';

import 'components/styles/login.scss';

const FormItem = Form.Item;
const LogoCompany = 'https://firebasestorage.googleapis.com/v0/b/gestor-documental-67434.appspot.com/o/Cabezote_Web_CR.gif?alt=media&token=71d4c061-1cd3-4ed7-b23c-ee25f5c2c916';

/**
 * Exporta el componente Login
 */
export const Login = () => {

  // Funcion que permite ejecutar todas las acciones del estado de redux
  const dispatch = useDispatch();
  // Selecciona los reductores
  const auth = useSelector(state => state.get('authenticate').get('login'));
  // Manejo de los estados del componente
  const [visible, setVisible] = useState(false);

  // Hook para el control de los formularios
  const [form] = Form.useForm();

  // Metodo que autentica al usuario
  const onFinish = values => {
    dispatch( email( values.email, values.password ) ).then( res => {
      if ( auth.type === AUTH.ERROR ) {
        notification.warning({
          message: 'Credenciales invalidas',
          description: 'Verifique su direccion de correo o contraseña',
          placement:  'topRight'
        });
      }
    });
  };

  /**
   * Metodo que renderiza el componente
   */
  return (
    <div className  = 'cuerpo' >

      <div className  = 'imagenInicio' >
        <img alt = "logo" src = { LogoCompany } />
      </div>
      <div className = 'login' >
      
        <Form form = { form } className = "login-form" onFinish = { onFinish } >

          <FormItem name = "email" help = "example@example.com"
            rules = {[
              { required: true, message: 'Debe ingresar una direccion de correo' },
              { type: 'email', message: 'Debe ingresar una direccion de correo valida' }
            ]} >
            <Input prefix = { <UserOutlined style = {{ color: 'rgba(0,0,0,.25)' }} /> } type = 'email' placeholder = "Email" />
          </FormItem>
          <FormItem name = "password"  help = "Minimo 6 caracteres"
            rules = {[{ required: true, message: 'Debe ingresar minimo 6 caracteres!' }]} >
            <Input prefix = { <LockOutlined style = {{ color: 'rgba(0,0,0,.25)' }} /> } type = "password" placeholder = "Password" />
          </FormItem>
          <FormItem valuePropName = "checked" initialValue = { true } >
            <Checkbox> Recordarme </Checkbox>
            <Button type = "link" className = "login-form-forgot"
              onClick = { e => {
                e.preventDefault();
                setVisible(true);
              }} > Recordar password </Button>
            <Button type = "primary" htmlType = "submit" className = "login-form-button" > Iniciar sesion </Button>
          </FormItem>
        </Form>

        <ModalRecoveryPassword visible = { visible } setVisible = { setVisible } />

      </div>
    </div>
  );
};