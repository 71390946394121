/**
 * Componente Search que permite llevar a cabo las operaciones del buscador
 */

import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Row, Col, Button, Spin, notification, Table, Input } from 'antd';
import { UpOutlined, DownOutlined, FilterFilled } from '@ant-design/icons'

import { DocumentsContext } from 'components/documents/context';
import { useListFolder } from 'components/documents/list_folders';
import { useFields } from 'components/search/fields';

import { searcher } from 'actions';
import { CUSTOMERS, REFERENCES, USERS } from 'types';
import { File } from 'components/documents/file';
import { Fields } from 'components/search/fields';
import 'components/styles/search.scss';

/**
 * Exporta el componente Search
 */
export const Search = () => {

  // Funcion que permite ejecutar todas las acciones del estado de redux
  const dispatch = useDispatch();
  // Selecciona los reductores
  const customers = useSelector(state => state.get('customers').get('read'));
  const references = useSelector(state => state.get('references').get('read'));
  const roles = useSelector(state => state.get('roles').get('roles').roles);
    
  // Manejo de los estados del componente
  const [expand, setExpand] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [files, setFiles] = useState({ loading: false, source: [] });

  // Hook para el control de los formularios
  const [form] = Form.useForm();

  // Una referencia al componente Html para el campo de busqueda
  const searchInput = useRef(null);

  // Importa el hook para el manejo de la lista de carpetas.
  const { folders } = useListFolder({ form });
  const { fields } = useFields();

  const { isClosing, file, user } = folders.current;

  /**
   * Metodo que permite ejecutar las consultas del buscador y guardar en el estado de la aplicación
   * el resultado final de la busqueda
   * @param {object} evt 
   */
  const onSearcher = values => {

    setLoading(true);
    setFiles({ loading: true, source: files.source });
    dispatch( searcher({ roles: roles.documents, fields: values }) ).then( files => {
      
      setLoading(false);
      setFiles({
        loading: false,
        source: files.map( file => ({
          key: file.ref.id,
          ref: file.ref,
          file: file.data.name,
          upload: file.data.date_of_upload.toDate().toLocaleDateString('es-CO', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }),
          id: file.ref.id
        }))
      });
    
    }).catch( error => {
      setLoading(false);
      setFiles({ loading: false, source: [] });
      notification.error({
        message: 'Error',
        description: "Se presento un error al tratar de realizar la busqueda",
        placement: 'topLeft'
      });
    });
        
  };

  /**
   * Metodo que se ejcuta en caso una validacion fallida en las reglas de los campos de busqueda
   */
  const onSearcherFailed = () => {
    setLoading(false);
    setFiles({ loading: false, source: [] });
  };

  /**
   * Metodo que limpia todos los campos del buscador
   */
  const onSearcherReset = () => {
    form.resetFields();
    setFiles({ loading: false, source: [] });
  };

  /**
   * Metodo que se ejecuta cuando se realiza la busqueda
   * @param {array} selectedKeys 
   * @param {object} confirm 
   */
  const onHandleSearch = ( selectedKeys, confirm ) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };
  
  /**
   * Metodo que se ejecuta cuando se limpian los campos de la busqueda
   * @param {function} clearFilters 
   */
  const onHandleReset = clearFilters => {
    clearFilters();
    setSearchText("");
  };
  
  useEffect(() => {
    dispatch({ type: USERS.READ.READ_USERS });
    dispatch({ type: CUSTOMERS.READ.READ_CUSTOMERS });
    dispatch({ type: REFERENCES.READ.READ_REFERENCE });
  }, [dispatch]);

  useEffect(() => {
    isClosing.set(true);
    user.roles.set(roles);
  }, [isClosing, user.roles, roles]);

  /**
   * Metodo que renderiza el componente
   */
  return (
    <DocumentsContext.Provider value = {{ folders, fields }} >
      <Spin spinning = {
          loading ? true : customers
            ? references
              ? customers?.type === CUSTOMERS.READ.READED_CUSTOMERS && references?.type === REFERENCES.READ.READED_REFERENCE
                ? false
                : true
              : true
            : true
        } delay = { 500 } >

        <Form form = { form } className = "search-form" onFinish = { onSearcher } onFinishFailed = { onSearcherFailed } >

          <Fields form = { form } expand = { expand } />
          
          <Row>
            <Col span = { 24 } style = {{ textAlign: 'right' }} >
              <Button type = "primary" htmlType = "submit" > Buscar </Button>
              <Button style = {{ marginLeft: 8 }} onClick = { onSearcherReset } >
                Limpiar campos
              </Button>
              <Button type = "link" style = {{ marginLeft: 8, fontSize: 12 }} onClick = { () => setExpand(!expand) } >
                + Criterios de busqueda { expand ? <UpOutlined /> : <DownOutlined /> }
              </Button>
            </Col>
          </Row>
          
        </Form>

        <div className = "search-result-list" >
          <Table
            bordered = { true }
            size = 'middle'
            dataSource = { files.source }
            pagination = {{ pageSize: 10 }}
            locale = {{ emptyText: 'No hay informacion para el filtro seleccionado' }}
            columns = {[
              {
                title: 'Archivo',
                dataIndex: 'file',
                key: 'file',
                sorter: (a, b) => a.file.localeCompare( b.file ),
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                  <div className = "custom-filter-dropdown" >
                    <Input
                      ref = { searchInput }
                      placeholder = "Buscar por archivo"
                      value = { selectedKeys[0] }
                      onChange = { e => setSelectedKeys( e.target.value ? [ e.target.value ] : [] ) }
                      onPressEnter = { () => onHandleSearch( selectedKeys, confirm ) }
                    />
                    <Button type = "primary" onClick = { () => onHandleSearch( selectedKeys, confirm ) } > Buscar </Button>
                    <Button onClick = { () => onHandleReset(clearFilters)} > Quitar filtro </Button>
                  </div>
                ),
                filterIcon: filtered => <FilterFilled style = {{ color: filtered ? '#108ee9' : '#aaa' }} />,
                onFilter: ( value, record ) => record.file.toLowerCase().includes( value.toLowerCase() ),
                onFilterDropdownVisibleChange: visible => { if ( visible ) setTimeout(() => searchInput.current.focus() ); },
                render: text => ( !searchText ? text :
                  <span>
                    { text.split( new RegExp(`(?<=${ searchText })`, 'i') ).map( ( fragment, i ) => (
                        fragment.toLowerCase() === searchText.toLowerCase()
                          ? <span key = { i } className = "highlight" > { fragment } </span>
                          : fragment
                    ))}
                  </span>
                )
              }, {
                title: 'Fecha de subida',
                dataIndex: 'upload',
                key: 'upload',
                sorter: (a, b) => a.upload.localeCompare( b.upload )
              }, {
                title: 'ID de archivo',
                dataIndex: 'id',
                key: 'id',
                sorter: (a, b) => a.id.localeCompare( b.id )
              }
            ]}
            onRow = {
              record => ({
                onClick: async () => file.set({
                  visible: true,
                  isLoad: true,
                  reference: record,
                  fields: await record.ref.get()
                })
              })
            }
          />
        </div>

        <File />
      
      </Spin>
    </DocumentsContext.Provider>
  );
};