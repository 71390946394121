/**
 * Acción de REDUX para la autenticación por email y password
 * Recibe como parametros el email y password 
 */

import { AUTH } from 'types';

/**
 * Exporta la función de autenticación por email y password
 * @param {string} email 
 * @param {string} password 
 */
export const email = ( email, password ) => ( dispatch, state, firebase ) => {
  return firebase().auth().signInWithEmailAndPassword( email, password )
    .catch( 
      error => dispatch({ 
        type: AUTH.ERROR,
        code: error.code,
        message: error.message
      })
    )
};