/**
 * Acción de REDUX que permite llevar a cabo todas las operaciones sobre las plantillas para la clonación de carpetas 
 */

import { FOLDERS } from 'types';

/**
 * Exporta las funciones para el manejo de las plantillas
 */
export const folders = {

  /**
   * Objeto Templates
   */
  templates: {
    
    /**
     * Función que permite crear una plantilla.
     * Recibe como parametros:
     * Roles del usuario que esta realizando la operación
     * Información de la plantilla que se va a crear
     */
    create: ({ roles, template }) => ( dispatch, state, firebase ) => {

      dispatch({ type: FOLDERS.TEMPLATES.CREATE.CREATING_TEMPLATE });
    
      return new Promise( async ( resolve, reject ) => {
        if ( roles.create ) {
          try {

            const db = firebase().firestore();
            const uid = firebase().auth().currentUser.uid;
            const batch = db.batch();

            const log_audit = db.collection('audit').doc();
            const reference = db.collection(`templates/folders/${ template.name }`);
            const templates = await db.doc('templates/folders').get();
            const isExists = templates.data()?.templates?.filter(tmpl => tmpl.name === template.name).length > 0;

            const after = {
              name: template.name,
              description: template.description,
              reference: reference.path
            }

            if (!isExists) {
              batch.set(log_audit, { uid, eventType: FOLDERS.TEMPLATES.CREATE.CREATE_TEMPLATE, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
              batch.set(log_audit.collection('after').doc(), after);
            }
            batch[templates.exists ? 'update' : 'set'](templates.ref, { templates: firebase().firestore.FieldValue.arrayUnion(after) });

            batch.commit().then(() => {
              resolve(
                dispatch({ type: FOLDERS.TEMPLATES.CREATE.CREATED_TEMPLATE, template: reference })
              )
            }).catch(
              error => reject(
                dispatch({ type: FOLDERS.TEMPLATES.CREATE.ERROR, code: error.code, message: error.message })
              )
            );

          } catch ( error ) {
            reject(
              dispatch({ type: FOLDERS.TEMPLATES.CREATE.ERROR, code: error.code, message: error.message })
            )
          }
        } else reject( dispatch({ type: FOLDERS.TEMPLATES.CREATE.ERROR, code: 0, message: 'Permisos insuficientes para crear plantillas' }) );
        
      })
  
    },

    /**
     * Función que permite consultar la lista plantillas.
     * Recibe como parametros:
     * Roles del usuario que esta realizando la operación
     */
    read: ({ roles }) => ( dispatch, state, firebase ) => {

      dispatch({ type: FOLDERS.TEMPLATES.READ.READING_TEMPLATE });      

      return new Promise( ( resolve, reject ) => {
        if ( roles.read ) {

          const db = firebase().firestore();
          const templates = db.doc('templates/folders');

          templates.get().then( doc => {

            // const data = doc.exists ? doc.data().templates ? doc.data().templates : [] : [];            
            resolve( dispatch({ type: FOLDERS.TEMPLATES.READ.READED_TEMPLATE, template: doc }) );

          }).catch( error => reject( dispatch({ type: FOLDERS.TEMPLATES.READ.ERROR, code: error.code, message: error.message }) ) );

        } else reject( dispatch({ type: FOLDERS.TEMPLATES.READ.ERROR, code: 0, message: 'Permisos insuficientes para consultar la lista de plantillas' }) )
      })

    },

    /**
     * Función que permite eliminar una plantilla.
     * Recibe como parametros:
     * Roles del usuario que esta realizando la operación
     * Referencia e información de la plantilla
     */
    delete: ({ roles, template }) => ( dispatch, state, firebase ) => {

      dispatch({ type: FOLDERS.TEMPLATES.DELETE.DELETING_TEMPLATE });

      return new Promise( async ( resolve, reject ) => {
        if ( roles.delete ) {
          try {

            const doc = await template.reference.ref.get();
            const data = await template.reference.ref.collection( template.name ).get();

            for ( const doc of data.docs ) await doc.ref.delete();

            const db = firebase().firestore();
            const uid = firebase().auth().currentUser.uid;
            const batch = db.batch();

            const log_audit = db.collection('audit').doc();
            const before = {
              name: template.name,
              description: template.description,
              reference: template.path
            }

            batch.set(log_audit, { uid, eventType: FOLDERS.TEMPLATES.DELETE.DELETE_TEMPLATE, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
            batch.set(log_audit.collection('before').doc(), before);
            batch.update(doc.ref, { templates: firebase().firestore.FieldValue.arrayRemove(before) });

            await batch.commit();
            return resolve(
              dispatch({ type: FOLDERS.TEMPLATES.DELETE.DELETED_TEMPLATE, template: doc.ref })
            );
            
          } catch ( error ) {
            reject(
              dispatch({
                type: FOLDERS.TEMPLATES.DELETE.ERROR,
                code: error.code,
                message: error.message
              })
            );
          }
        } else reject( dispatch({ type: FOLDERS.TEMPLATES.DELETE.ERROR, code: 0, message: 'Permisos insuficientes para eliminar la lista de plantillas' }) )
      })

    },

    /**
     * Función que permite actualizar una plantilla.
     * Recibe como parametros:
     * Roles del usuario que esta realizando la operación
     * Referencia e información de la plantilla
     */
    update: ({ roles, template }) => ( dispatch, state, firebase ) => {
    
      dispatch({ type: FOLDERS.TEMPLATES.UPDATE.UPDATING_TEMPLATE });
    
      return new Promise( async ( resolve, reject ) => {
        if ( roles.update ) {
          try {

            const db = firebase().firestore();

            const doc = await db.doc('templates/folders').get();
            const data = doc.exists ? doc.data()?.templates || [] : [];
            const index = data.findIndex( tpl => tpl.name === template.name );
            const reference = db.collection(`templates/folders/${ template.name }`);

            if ( data.filter( tpl => tpl.name === template.name ).length <= 0 ) resolve({
              type: FOLDERS.TEMPLATES.UPDATE.UPDATED_TEMPLATE,
              template: reference
            });

            const uid = firebase().auth().currentUser.uid;
            const batch = db.batch();

            const log_audit = db.collection('audit').doc();
            const before = { ...data[ index ] };
              
            data[ index ].description = template.description;

            const after = { ...data[ index ] };

            batch.set(log_audit, { uid, eventType: FOLDERS.TEMPLATES.UPDATE.UPDATE_TEMPLATE, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
            batch.set(log_audit.collection('before').doc(), before);
            batch.set(log_audit.collection('after').doc(), after);
            batch.update(db.doc('templates/folders'), { templates: data });

            await batch.commit();
            return resolve(
              dispatch({ type: FOLDERS.TEMPLATES.UPDATE.UPDATED_TEMPLATE, template: reference })
            );

          } catch ( error ) { reject( dispatch({ type: FOLDERS.TEMPLATES.UPDATE.ERROR, code: error.code, message: error.message }) ); }
        } else reject( dispatch({ type: FOLDERS.TEMPLATES.UPDATE.ERROR, code: 0, message: 'Permisos insuficientes para actualizar plantillas' }) );
        
      })
      
    }

  }
    
}