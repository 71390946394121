/**
 * Importación de las dependencias del proyecto
 */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Table, Skeleton } from 'antd';

import TYPES from 'components/audit/map_event_types';
import { useAudit } from 'components/audit/context';
import { EventDataView } from 'components/audit/event_data_view';

/**
 * Componente de función que visualiza los eventos generados en la base de datos,
 * por medio de una tabla
 * @returns {React.ReactNode}
 */
export const EventTable = () => {

    // Hooks para el manejo interno del componente
    const [source, setSource] = useState([]);
    // Obtiene una instancia del contexto del log de auditoria
    const { isLoading, eventDate, eventType, eventData, users, customers } = useAudit();
    // Hook que escucha todas los cambios en la colección "audit" de firestore
    useFirestoreConnect([{
        collection: 'audit',
        orderBy: [['eventDate', 'desc']],
        where: [
            ['eventDate', '<=', eventDate.end.toDate()],
            ['eventDate', '>=', eventDate.start.toDate()],
            ...eventType.types.map(type => ['eventType', '==', type]),
            ...users.selected.map(user => ['uid', '==', user])
        ]
    }]);

    /**
     * Hook que permite obtener las documentos obtenidas de la colección "audit"
     * @returns {any}
     */
    const events = useSelector(state => state.get('firestore').data.audit);

    /**
     * Función que establece el estado incial del componente y se ejecuta
     * cada vez que alguno de los parametros events, users.source cambia.
     */
    useEffect(() => {
        setSource(
            Object.getOwnPropertyNames(events || {}).map(
                (prop, index) => {
        
                    const eventType = TYPES[events[prop]?.eventType] || "Sin evento asociado";
                    const eventDate = events[prop]?.eventDate?.toDate();
                    const user = users.source.filter(user => user.uid === events[prop]?.uid )[0];
        
                    return {
                        key: index,
                        eventType,
                        eventDate: `${ eventDate?.toLocaleDateString('es-CO', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        }) } - ${ eventDate?.toLocaleTimeString('es-CO') }`,
                        uid: user?.['Nombre completo'],
                        company: user?.company?.['Razon social'] || "Sin una empresa asociada",
                        cid: user?.company?.cid,
                        view: { reference: `audit/${ prop }`, type: eventType }
                    };
                }
            )
        );
    }, [events, users.source]);

    // Retorna el componente con los datos de los eventos en una tabla
    return (
        <Skeleton loading = { isLoading } >
            <Table bordered = { true } size = "small" locale = {{ emptyText: "No hay registros en el log de eventos" }}
                dataSource = { customers.selected?.length <= 0 ? source : source.filter(item => customers.selected.includes(item.cid)) }
                onRow = { record => ({
                    onClick: () => eventData.set({
                        visible: true,
                        reference: record.view.reference,
                        type: record.view.type,
                    })
                })} >
                <Table.Column title = "Descripción del evento" dataIndex = "eventType" key = "eventType" />
                <Table.Column title = "Fecha del evento" dataIndex = "eventDate" key = "eventDate" />
                <Table.ColumnGroup title = "Usuario del evento" >
                    <Table.Column title = "Nombre" dataIndex = "uid" key = "uid" />
                    <Table.Column title = "Empresa" dataIndex = "company" key = "company" />
                </Table.ColumnGroup>
            </Table>
            <EventDataView />
        </Skeleton>
    );
};