/**
 * Exporta los reductores de la aplicación
 */

import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { combineReducers } from 'redux-immutable';
import { authenticate } from 'reducers/login';
import { documents } from 'reducers/documents';
import { customers } from 'reducers/customers';
import { users } from 'reducers/users';
import { roles } from 'reducers/roles';
import { upload } from 'reducers/upload';
import { references } from 'reducers/references';
import { folders } from 'reducers/folders';

export const reducers = combineReducers({
  authenticate: authenticate,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  documents: documents,
  customers: customers,
  users: users,
  roles: roles,
  upload: upload,
  references: references,
  folders: folders
});

export default reducers;