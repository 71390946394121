/**
 * Reductor de REDUX para controlar los estados del componente de templates
 */

import { FOLDERS } from 'types';
import { Map } from 'immutable';

/**
 * Exporta el reductor folders
 * @param {object} state 
 * @param {object} action 
 */
export const folders = ( state = Map({ type: FOLDERS.TEMPLATES.READ.READ_TEMPLATE }), action ) => {

  switch ( action.type ) {

    case FOLDERS.TEMPLATES.CREATE.CREATE_TEMPLATE:
      return state.set('create', {
        type: action.type
      });
    
    case FOLDERS.TEMPLATES.CREATE.CREATING_TEMPLATE:
      return state.set('create', {
        type: action.type,
      });
  
    case FOLDERS.TEMPLATES.CREATE.CREATED_TEMPLATE:
      return state.set('create', {
        type: action.type,
        reference: action.reference
      });

    case FOLDERS.TEMPLATES.CREATE.ERROR:
      return state.set('create', {
        type: action.type,
        code: action.code,
        message: action.message
      });
    
    case FOLDERS.TEMPLATES.READ.READ_TEMPLATE:
      return state.set('read', {
        type: action.type
      });
    
    case FOLDERS.TEMPLATES.READ.READING_TEMPLATE:
      return state.set('read', {
        type: action.type,
      });
  
    case FOLDERS.TEMPLATES.READ.READED_TEMPLATE:
      return state.set('read', {
        type: action.type,
        template: action.template
      });

    case FOLDERS.TEMPLATES.READ.ERROR:
      return state.set('read', {
        type: action.type,
        code: action.code,
        message: action.message
      });

    case FOLDERS.TEMPLATES.UPDATE.UPDATE_TEMPLATE:
      return state.set('update', {
        type: action.type
      });
    
    case FOLDERS.TEMPLATES.UPDATE.UPDATING_TEMPLATE:
      return state.set('update', {
        type: action.type,
      });
  
    case FOLDERS.TEMPLATES.UPDATE.UPDATED_TEMPLATE:
      return state.set('update', {
        type: action.type,
        template: action.template
      });

    case FOLDERS.TEMPLATES.UPDATE.ERROR:
      return state.set('update', {
        type: action.type,
        code: action.code,
        message: action.message
      });

      case FOLDERS.TEMPLATES.DELETE.DELETE_TEMPLATE:
      return state.set('delete', {
        type: action.type
      });
    
    case FOLDERS.TEMPLATES.DELETE.DELETING_TEMPLATE:
      return state.set('delete', {
        type: action.type,
      });
  
    case FOLDERS.TEMPLATES.DELETE.DELETED_TEMPLATE:
      return state.set('delete', {
        type: action.type,
        template: action.template
      });

    case FOLDERS.TEMPLATES.DELETE.ERROR:
      return state.set('delete', {
        type: action.type,
        code: action.code,
        message: action.message
      });

    default:
      return state;

  }

}