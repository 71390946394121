/**
 * Componente Customers que permite llevar acabo todas las operaciones sobre los clientes
 */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Table, Spin, Modal, Form, Input, notification, Divider, Select, List } from 'antd';

import { customers as customersActions } from 'actions';
import { CUSTOMERS } from 'types';
import { ModalAddUser } from 'components/customers/modal_add_user';

import 'components/styles/table.scss'

/**
 * Exporta el componente Customers
 */
export const Customers = () => {

  // Funcion que permite ejecutar todas las acciones del estado de redux
  const dispatch = useDispatch();
  // Selecciona los reductores
  const auth = useSelector(state => state.get('authenticate').get('login'));
  const roles = useSelector(state => state.get('roles').get('roles').roles);
  const read = useSelector(state => state.get('customers').get('read'));

  // Manejo de los estados del componente
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [select, setSelect] = useState('NIT');
  const [update, setUpdate] = useState(false);
  const [id, setId] = useState("");
  const [users, setUsers] = useState({ visible: false, loading: false, id: "" });
  const [table, setTable] = useState({ loading: false });

  // Hook para el control de los formularios
  const [form] = Form.useForm();

  /**
   * Metodo del componente que permite consultar la lista de clientes
   * @param {object} filter 
   */
  const onRead = filter => {
    dispatch(
      customersActions.read({ 
        roles: roles.customers,
        filter,
        uid: roles.documents.level === 'owner' ? auth.user.uid : undefined
      })
    );
  };

  /**
   * Metodo del componente que permite crear un cliente
   */
  const onCreate = values => {
    setLoading(true);
    dispatch( customersActions.create({ roles: roles.customers, nit: values.nit, name: values.name }) ).then( () => {
      setVisible(false);
      setLoading(false);
      form.resetFields();
      dispatch( customersActions.read({ roles: roles.customers }) );
    }).catch( error => {
      notification.error({
        message: 'Error',
        description: 'Se presento un error al tratar de crear el cliente',
        placement:  'topRight'
      });
      setVisible(false);
      setLoading(false);
    });
  };

  /**
   * Metodo del componente que permite actualizar un cliente
   * @param {object} record 
   */
  const onUpdate = values => {
    setLoading(true); 
    dispatch(
      customersActions.update({
        roles: roles.customers,
        data: { id, fields: { 'Razon social': values.name, 'NIT': values.nit } }
      })
    ).then( () => {
      setVisible(false);
      setLoading(false);
      form.resetFields();
      dispatch( customersActions.read({ roles: roles.customers }) );
    }).catch( error => {
      notification.error({
        message: 'Error',
        description: 'Se presento un error al tratar de actualizar el cliente',
        placement:  'topRight'
      });
      setVisible(false);
      setLoading(false);
    });
  };

  /**
   * Metodo del componente que permite eliminar un cliente
   * @param {object} record 
   */
  const onDelete = record => {
    dispatch( customersActions.delete({ roles: roles.customers, id: record.key }) ).then( () => {
      form.resetFields();
      dispatch( customersActions.read({ roles: roles.customers }) );
    })
    .catch( error => {
      notification.error({
        message: 'Error',
        description: 'Se presento un error al tratar de eliminar el cliente',
        placement:  'topRight'
      });
    });
  };

  /**
   * Metodo del componente que permite asignar un usuario a un cliente
   */
  const onAddUser = ({values, onError}) => {

    const id = users.id;
    setUsers({ visible: true, loading: true, id: "" });

    dispatch( customersActions.addUser({ roles: roles.customers, id, email: values.email }) ).then( () => {
      form.resetFields();
      setUsers({ visible: false, loading: false, id: "" });
      dispatch( customersActions.read({ roles: roles.customers }) );
    }).catch( error => {
      onError(error);
      setUsers({ visible: true, loading: false, id });
    });

  };

  /**
   * Metodo del componente que permite retirar un usuario de un cliente
   * @param {object} record 
   * @param {object} item 
   */
  const onRemoveUser = ( record, item ) => {
    dispatch( customersActions.removeUser({ roles: roles.customers, id: record.key, email: item.email }) ).then( () => {
      form.resetFields();
      dispatch( customersActions.read({ roles: roles.customers }) );
    }).catch( error => {
      notification.error({
        message: 'Error',
        description: 'Se presento un error al tratar de quitar el usuario',
        placement:  'topRight'
      });
    });
  }

  /**
   * Metodo del componente que permite asignar un usuario como administrador a un cliente
   * @param {object} record 
   * @param {object} item 
   */
  const setUserAdmin = ( record, item ) => {

    setTable({ loading: true });
    dispatch( customersActions.setUserAdmin({ roles: roles.customers, id: record.key, item }) ).then( () => {

      form.resetFields();
      dispatch( customersActions.read({ roles: roles.customers }) ).then( () => {
        setTable({ loading: false });
      }).catch( error => {
        notification.error({
          message: 'Error',
          description: 'Se presento un error al tratar de actulizar el usuario',
          placement:  'topRight'
        });
        setTable({ loading: false });
      });
    
    }).catch( error => {
      notification.error({
        message: 'Error',
        description: 'Se presento un error al tratar de actulizar el usuario',
        placement:  'topRight'
      });
      setTable({ loading: false });
    });

  };

  // Ejecuta la funcion una vez termina de renderizar el componente
  useEffect(() => {
    dispatch( customersActions.read({ roles: roles.customers }) );
  }, [dispatch, roles.customers]);

  /**
   * Metodo que rederiza el componente
   */    
  return (
    <div>
            
      <div style = {{ marginBottom: 15 }} >
        <Input.Search
          enterButton = "Search"
          onSearch = { value => onRead({ field: select, value }) }
          addonBefore = { (
            <Select
              defaultValue = "NIT"
              style = {{ width: 120 }}
              onSelect = { value => setSelect(value) } >
              <Select.Option value = "NIT" > Documento </Select.Option>
              <Select.Option value = "Razon social" > Nombre </Select.Option>
            </Select>
          )} />
      </div>
      
      { read?.type === CUSTOMERS.READ.READED_CUSTOMERS ?
          <Table bordered size = 'middle' loading = { table.loading }
            locale = {{ emptyText: 'La lista de clientes se encuentra vacia' }}
            title = { () => (
              roles?.customers?.create ?
                <Button type = 'dashed'
                  disabled = { read?.type !== CUSTOMERS.READ.READED_CUSTOMERS }
                  onClick = { () => {
                    form.resetFields();
                    setVisible(true);
                    setUpdate(false);
                  }}
                > Nuevo cliente </Button> : null
            )}
            columns = {[
              { title: 'Razon social', dataIndex: 'name', key: 'name' },
              { title: 'NIT', dataIndex: 'nit', key: 'nit' },
              { title: 'Acciones', key: 'action',
                render: ( text, record ) => (
                  <span>
                    
                    { roles?.customers?.update ?
                        <>
                          <Button type = "link"
                            onClick = { () => {
                              form.resetFields();
                              setVisible(true);
                              setUpdate(true);
                              setId(record.key);
                              form.setFieldsValue({ 
                                nit: record.nit,
                                name: record.name
                              });
                            }}
                          > Editar </Button>
                          <Divider type = "vertical" />
                        </> : null }

                    { roles?.customers?.delete ?
                        <>
                          <Button type = "link"
                            onClick = { () => {
                              Modal.confirm({
                                title: `Eliminar cliente ${ record.name }`,
                                content: 'Esta seguro de eliminar el cliente seleccionado?',
                                okText: 'Eliminar',
                                okType: 'danger',
                                cancelText: 'Cancelar',
                                onOk: () => onDelete( record )
                              })
                            }}
                          > Eliminar </Button>
                          <Divider type = "vertical" />
                        </> : null }

                  </span>
                )
              }
            ]}
            dataSource = { 
              read?.customers.map( customer => ({
                  key: customer.id,
                  name: customer.data['Razon social'],
                  nit: customer.data['NIT'],
                  users: customer.data['Usuarios']
              }))
            }
            expandedRowRender = {
              record => (
                <List itemLayout = "vertical" size = "small" bordered = { true }
                  locale = {{ emptyText: `No hay usuarios para ${ record.name }` }}
                  header = {
                    roles?.customers?.create ?
                      <Button type = 'dashed'
                        disabled = { read?.type !== CUSTOMERS.READ.READED_CUSTOMERS }
                        onClick = { () => {
                          form.resetFields();
                          setUsers({ visible: true, loading: false, id: record.key });
                        }} 
                      > Agregar usuario </Button> : null
                  }
                  dataSource = { record.users }
                  renderItem = { item => (
                    <List.Item
                      actions = {[
                        roles?.customers?.delete ?
                          <Button type = "link"
                            onClick = { () => {
                              Modal.confirm({
                                title: `Quitar usuario ${ item.email }`,
                                content: 'Esta seguro de quitar el usuario seleccionado?',
                                okText: 'Quitar',
                                okType: 'danger',
                                cancelText: 'Cancelar',
                                onOk: () => onRemoveUser( record, item )
                              })
                            }}
                          > Quitar </Button> : null,
                        roles?.customers?.update
                          ? <Button type = "link"
                              onClick = { () => {
                                Modal.confirm({
                                  title: `${ item.isAdmin ? 'Quitar' : 'Agregar' } usuario ${ item.email } como administrador`,
                                  content: `Esta seguro de ${ item.isAdmin ? 'quitar' : 'agregar' } el usuario seleccionado como administrador?`,
                                  okText: `${ item.isAdmin ? 'Quitar' : 'Agregar' }`,
                                  okType: 'danger',
                                  cancelText: 'Cancelar',
                                  onOk: () => setUserAdmin( record, item )
                                })
                              }}
                            > { item.isAdmin ? 'Quitar' : 'Agregar' } como administrador </Button>
                          : null
                      ]} > { item.email } </List.Item>
                  )} />
              )
            }
          /> : read?.type === CUSTOMERS.READ.READING_CUSTOMERS ? <div style = {{ textAlign: 'center' }} > <Spin /> </div> : null }
      
      { roles?.customers?.create || roles?.customers?.update ?
          <Modal
            forceRender
            visible = { visible }
            confirmLoading = { loading }
            title = { !update ? "Nuevo cliente" : "Editar cliente" }
            okText = { !update ? "Crear" : "Editar" }
            cancelText = "Cancelar"
            maskClosable = { false }
            closable = { false }
            onOk = { () => form.submit() }
            onCancel = { () => setVisible(false) }
          >
            <Form name = "create_update_customer" form = { form } layout = "vertical" onFinish = { !update ? onCreate : onUpdate } >
              <Form.Item name = "nit" label = "Nit"
                rules = {[{ required: true, message: 'El numero de nit es requerido' }]} >
                <Input placeholder = 'NIT'  />
              </Form.Item>
              <Form.Item name = "name" label = "Razon social"
                rules = {[{ required: true, message: 'La razon social es requerida' }]} >
                <Input placeholder = 'razon social' />
              </Form.Item>
            </Form>
          </Modal> : null }

      { roles?.customers?.create ?
          <ModalAddUser
            users = { users }
            onAddUser = { onAddUser }
            onCancel = { () =>  setUsers({ visible: false, loading: false, id: "" }) } />
        : null }

    </div>
  )

};