import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Modal, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { AUTH } from 'types';
import { forgot } from 'actions';

const FormItem = Form.Item;

export const ModalRecoveryPassword = ({ visible, setVisible }) => {

    // Funcion que permite ejecutar todas las acciones del estado de redux
    const dispatch = useDispatch();
    // Selecciona los reductores
    const auth = useSelector(state => state.get('authenticate').get('login'));

    // Manejo de los estados del componente
    const [loading, setLoading] = useState(false);
    // Hook para el control de los formularios
    const [form] = Form.useForm();

    // Metodo del formulario en la ventana modal
    const onOk = () => form.submit();

    // Metodo que permite recuperar el password del usuario
    const onRecovery = values => {
        setLoading(true);
        dispatch( forgot( values.forgot) ).then( res => {
            setLoading(false);
            auth?.type !== AUTH.ERROR
                ? setVisible(false)
                : notification.warning({
                    message: 'Correo invalido',
                    description: 'Verifique su direccion de correo',
                    placement:  'topRight'
                });
        });      
    };

    return (
        <Modal
            forceRender
            title = 'Recordar password'
            cancelText = 'Cancelar'
            okText = 'Enviar'
            maskClosable = { false }
            destroyOnClose = { true }
            closable = { false }
            visible = { visible }
            confirmLoading = { loading }
            onCancel = { () => setVisible(false) }
            onOk = { onOk } >
            <Form form = { form } onFinish = { onRecovery } >
                <p> Enviaremos un email a tu cuenta de correo para reestablecer el password </p>
                <FormItem name = "forgot"
                    rules = {[
                        { required: true, message: 'Debe ingresar una direccion de correo' },
                        { type: 'email', message: 'Debe ingresar una direccion de correo valida' }
                    ]} >
                    <Input type = "email" placeholder = "Email" prefix = { <UserOutlined style = {{ color: 'rgba(0,0,0,.25)' }} /> } />
                </FormItem>
            </Form>
        </Modal>
    )
}