/**
 * Componente Documents que permite llevar acabo todas las operaciones sobre las carpetas
 */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Input, Select } from 'antd';

import 'components/styles/table.scss'
import { documents as documentsActions, customers as customersActions } from 'actions';
import { CUSTOMERS, UPLOAD, DOCUMENTS } from 'types';

import { DocumentsContext } from 'components/documents/context';

import { ListFolders, useListFolder } from 'components/documents/list_folders';
import { ModalFolder, useModalFolder } from 'components/documents/modal_folder';
import { ModalTemplate, useModalTemplate } from 'components/documents/modal_template';
import { ModalSearchFile, useModalSearchFile } from 'components/documents/modal_search_file';
import { Permissions } from 'components/documents/permissions';
import { File } from 'components/documents/file';

/**
 * Exporta el componente Documents
 */
export const Documents = () => {

  // Funcion que permite ejecutar todas las acciones del estado de redux
  const dispatch = useDispatch();
  // Selecciona los reductores
  const docs = useSelector(state => state.get('documents').get('read'));
  const auth = useSelector(state => state.get('authenticate').get('login'));
  const roles = useSelector(state => state.get('roles').get('roles').roles);
  const customers = useSelector(state => state.get('customers').get('read'));

  // Manejo de los estados del componente
  const [select, setSelect] = useState("NIT");
  
  // Importa el hook para el manejo de la venta modal de carpetas.
  const [form] = useModalFolder();
  // Importa el hook para el manejo de la venta modal de plantillas.
  const { templates } = useModalTemplate();
  // Importa el hook para el manejo de la busqueda de archivos.
  const { search } = useModalSearchFile();
  // Importa el hook para el manejo de la lista de carpetas.
  const { folders } = useListFolder({ form });
  
  /**
   * Metodo que permite cargar los clientes asignados a un usuario
   * @param {object} value 
   */
  const loadCustomers = value => {

    folders.set({ ...folders.data, isLoad: false });
    folders.current.isUpload.set(false);
    folders.list.set({ loading: true, columns: [], source: [] });

    if (!value || value.trim().length <= 0) {
      dispatch({ type: CUSTOMERS.READ.READ_CUSTOMERS });
      return;
    }

    dispatch(
      customersActions.read({
        roles: roles.documents,
        filter: { field: select, value }
      })
    ).then(({ customers }) => {

      // eslint-disable-next-line no-unused-expressions
      customers?.forEach( 
        customer => dispatch( documentsActions.read({ uid: customer.id, roles: roles.documents }) ).then(({ data }) => {

          const isUsers = Array.isArray(customer.data['Usuarios']);
          const user = isUsers ? customer.data['Usuarios'].filter( user => user.reference.id === auth.user.uid )[0] : [];
          
          folders.routes.set([{ name: customer.data['Razon social'], reference: data }]);
          folders.current.reference.set(data);
          folders.current.user.isRoot.set(roles?.documents?.level === 'all');
          folders.current.user.isAdmin.set(user?.isAdmin ? true : false);
          folders.current.user.permissions.set({ visible: false, users: customer?.data['Usuarios'] || [] });
          folders.set({ ...folders.data, isLoad: true });
        })
      );

    }).catch(() => folders.list.set({ loading: false, columns: [], source: [] }));

  };

  // Ejecuta la funcion una vez termina de renderizar el componente
  useEffect(() => {
    dispatch({ type: CUSTOMERS.READ.READ_CUSTOMERS });
    dispatch({ type: UPLOAD.UPLOAD });
    dispatch({ type: DOCUMENTS.READ.READ_DOCUMENTS });
  }, [ dispatch ]);

  /**
   * Metodo que rederiza el componente
   */
  return <DocumentsContext.Provider value = {{ folders, templates, search }} >
      
    <div style = {{ marginBottom: 15 }} >
      <Input.Search
        enterButton = "Buscar"
        onSearch = { loadCustomers }
        addonBefore = { (
          <Select
            defaultValue = "NIT"
            style = {{ width: 120 }}
            onSelect = { value =>  setSelect(value) } >
            <Select.Option value = "NIT" > Documento </Select.Option>
            <Select.Option value = "Razon social" > Nombre </Select.Option>
          </Select>
        )}
      />
    </div>

    { customers?.type === CUSTOMERS.READ.READED_CUSTOMERS
        ? customers?.customers?.length > 0
          ? <ListFolders /> : null
        : customers?.type === CUSTOMERS.READ.READING_CUSTOMERS 
          ? <div style = {{ textAlign: 'center' }} > <Spin /> </div>
          : null }

    { folders.current.user.roles?.folders?.create && !folders.current.isClosing.data ?
        <>
          <ModalFolder form = { form } />
          <ModalTemplate />
        </>
    : null }

    { docs?.data && folders.current.isUpload.data ? <ModalSearchFile /> : null }

    <Permissions />
    <File />
    
  </DocumentsContext.Provider>
};