/**
 * Acción de REDUX que envia un correo para recuperar el password del usuario
 * Recibe como parametros el email
 */

import { AUTH } from 'types';

/**
 * Exporta la función de recuperación de password
 * @param {string} email 
 */
export const forgot = ( email ) => ( dispatch, state, firebase ) => {

  dispatch({ type: AUTH.LOGGINGIN });

  return firebase().auth().sendPasswordResetEmail( email )
    .catch( 
      error => dispatch({ 
        type: AUTH.ERROR,
        code: error.code,
        message: error.message
      })  
    )
    
};