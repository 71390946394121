/**
 * Importación de las dependencias del proyecto
 */
import React from 'react';
import { PageHeader } from 'antd';

import { EventFields } from 'components/audit/event_fields';
import { EventTable } from 'components/audit/event_table';
import AuditProvider from 'components/audit/context';

/**
 * Componente de función que incializa los elementos del Log de auditoria
 * @returns {React.ReactNode}
 */
export const Audit = () => (
    <AuditProvider >
        <PageHeader title = "Log de auditoria" subTitle = "Registro de eventos" >
            <EventFields />
            <EventTable />
        </PageHeader>
    </AuditProvider>
);