/**
 * Reductor de REDUX para controlar los estados del componente de usuarios
 */

import { USERS } from 'types';
import { Map } from 'immutable';

/**
 * Exporta el reductor users
 * @param {object} state 
 * @param {object} action 
 */
export const users = ( state = Map({ type: USERS.READ.READ_USERS }), action ) => {

  switch ( action.type ) {

    case USERS.CREATE.CREATE_USERS:
      return state.set('create', {
        type: action.type
      });
    
    case USERS.CREATE.CREATING_USERS:
      return state.set('create', {
        type: action.type,
      });
  
    case USERS.CREATE.CREATED_USERS:
      return state.set('create', {
        type: action.type,
        users: action.users
      });

    case USERS.CREATE.ERROR:
      return state.set('create', {
        type: action.type,
        code: action.code,
        message: action.message
      });
    
    case USERS.READ.READ_USERS:
      return state.set('read', {
        type: action.type
      });
    
    case USERS.READ.READING_USERS:
      return state.set('read', {
        type: action.type,
      });
  
    case USERS.READ.READED_USERS:
      return state.set('read', {
        type: action.type,
        users: action.users
      });

    case USERS.READ.ERROR:
      return state.set('read', {
        type: action.type,
        code: action.code,
        message: action.message
      });

    case USERS.UPDATE.UPDATE_USERS:
      return state.set('update', {
        type: action.type
      });
    
    case USERS.UPDATE.UPDATING_USERS:
      return state.set('update', {
        type: action.type,
      });
  
    case USERS.UPDATE.UPDATED_USERS:
      return state.set('update', {
        type: action.type,
        users: action.users
      });

    case USERS.UPDATE.ERROR:
      return state.set('update', {
        type: action.type,
        code: action.code,
        message: action.message
      });

      case USERS.DELETE.DELETE_USERS:
      return state.set('delete', {
        type: action.type
      });
    
    case USERS.DELETE.DELETING_USERS:
      return state.set('delete', {
        type: action.type,
      });
  
    case USERS.DELETE.DELETED_USERS:
      return state.set('delete', {
        type: action.type,
        users: action.users
      });

    case USERS.DELETE.ERROR:
      return state.set('delete', {
        type: action.type,
        code: action.code,
        message: action.message
      });

    default:
      return state;

  }

}