/**
 * Componente References que permite llevar a cabo todas las operaciones de referencias
 */

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Select, Table, Spin, Button, Form, Modal, Divider } from 'antd';

import { references } from 'actions';
import { REFERENCES } from 'types';

/**
 * Exporta el componente References 
 */
export const References = () => {

  // Funcion que permite ejecutar todas las acciones del estado de redux
  const dispatch = useDispatch();
  // Selecciona los reductores
  const roles = useSelector(state => state.get('roles').get('roles').roles);
  const read = useSelector(state => state.get('references').get('read'));

  // Manejo de los estados del componente
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [update, setUpdate] = useState(false);
  const [row, setRow] = useState({ key: [], data: [] });

  // Hook para el control de los formularios
  const [form] = Form.useForm();

  // Metodo del formulario en la ventana modal
  const onOk = () => form.submit();

  /**
   * Metodo que permite crear una refrencia
   */
  const onCreate = values => {

    setLoading(true);
    setVisible(true);
    dispatch(
      references.create({
        roles: roles.references,
        reference: {
          name: values.name,
          type: values.type
        }
      })
    ).then( () => {

      form.resetFields();
      setLoading(false);
      setVisible(false);
      onRead();

    }).catch( error => {
          
      Modal.error({
        title: 'Error',
        content: 'Se presento un error al tratar de crear la referencia',
      });

      form.resetFields();
      setLoading(false);
      setVisible(false);
      onRead();

    });

  };

  /**
   * Metodo que permite consultar y cargar las referencias
   */
  const onRead = useCallback(() => {
    setLoading(true);
    dispatch(
      references.read({
        roles: roles.references
      })
    ).then( () => setLoading(false) ).catch( () => setLoading(false) )
  }, [dispatch, roles.references]);

  /**
   * Metodo que permite actualizar una referencia
   */
  const onUpdate = values => {
    
    setLoading(true);
    setVisible(true);
    dispatch(
      references.update({
        roles: roles.references,
        id: row.key[0],
        reference: {
          name: values.name,
          type: values.type
        }
      })
    ).then( () => {

      form.resetFields();
      setLoading(false);
      setVisible(false);
      setRow({ key: [], data: [] });
      onRead();

    }).catch( error => {
          
      Modal.error({
        title: 'Error',
        content: 'Se presento un error al tratar de actualizar la referencia',
      });

      form.resetFields();
      setLoading(false);
      setVisible(false);
      setRow({ key: [], data: [] });
      onRead();

    });

  }

  /**
   * Metodo que permite eliminar una referencia
   * @param {strinf} id 
   */
  const onDelete = id => {

    setLoading(true);
    dispatch( references.delete({ roles: roles.references, id }) ).then( () => {

      form.resetFields();
      setLoading(false);
      setRow({ key: [], data: [] });
      onRead();

    }).catch( error => {
          
      Modal.error({
        title: 'Error',
        content: 'Se presento un error al tratar de eliminar la referencia',
      });

      form.resetFields();
      setLoading(false);
      setRow({ key: [], data: [] });
      onRead();

    });

  }

  // Ejecuta la funcion una vez termina de renderizar el componente
  useEffect(() => { onRead(); }, [ onRead ]);

  /**
   * Metodo que renderiza el componente
   */
  return (
    <>
      { read?.type === REFERENCES.READ.READED_REFERENCE ?
          <Table
            bordered
            size = 'middle'
            loading = { loading }
            locale = {{ emptyText: 'La lista de referencias se encuentra vacia' }}
            title = { () => (
              <>
                
                { roles?.references.create ?
                    <Button type = 'dashed'
                      disabled = { read?.type !== REFERENCES.READ.READED_REFERENCE }
                      onClick = { () => {
                        form.resetFields();
                        setVisible(true);
                        setUpdate(false);
                      }} > Nueva referencia </Button> : null }

                <Divider type = "vertical" />                        

                { roles?.references.update ?
                    <Button type = 'dashed'
                      disabled = { !row.key.length > 0 }
                      onClick = { () => {
                        setVisible(true);
                        setUpdate(true);
                        form.setFields([
                          { name: "name", value: row.data[0]?.name },
                          { name: "type", value: row.data[0]?.type }
                        ]);
                      }} > Editar referencia </Button> : null }

                <Divider type = "vertical" />

                { roles.references.delete ?
                    <Button type = 'dashed'
                      disabled = { !row.key.length > 0 }
                      onClick = { () => {
                        Modal.confirm({
                          title: `Eliminar referencia ${ row.data[0].name }`,
                          content: 'Esta seguro de eliminar la referencia seleccionada?',
                          okText: 'Eliminar',
                          okType: 'danger',
                          cancelText: 'Cancelar',
                          onOk: () => onDelete(row.key[0])
                        })
                      }} > Eliminar referencia </Button> : null }

              </>
            )}
            columns = {[
              { title: 'Referencia', dataIndex: 'name', key: 'name' },
              { title: 'Tipo de dato', dataIndex: 'type', key: 'type' }
            ]}
            dataSource = {
              read.reference.map( ref => ({
                key: ref.id,
                name: ref.name,
                type: ref.type
              }))
            }
            rowSelection = {{
              type: 'radio',
              selectedRowKeys: row.key,
              onChange: ( key, row ) => setRow({ key, data: row }) 
            }}
          />
        : read?.type === REFERENCES.READ.READING_REFERENCE ? <div style = {{ textAlign: 'center' }} > <Spin /> </div> : null }

      { roles?.references.create || roles?.references.update ?
          <Modal
            forceRender
            visible = { visible }
            confirmLoading = { loading }
            title = { !update ? "Nueva referencia" : "Editar referencia" }
            okText = { !update ? "Crear" : "Editar" }
            cancelText = "Cancelar"
            maskClosable = { false }
            closable = { false }
            onOk = { onOk }
            onCancel = { () =>  setVisible(false) } >
            <Form form = { form } layout = "vertical" onFinish = { !update ? onCreate : onUpdate } >
              <Form.Item label = "Nombre de la referencia" name = "name"
                rules = {[{ required: true, message: 'El nombre de la referencia es requerido' }]} >
                <Input />
              </Form.Item>
              <Form.Item label = "Tipo de dato" name = "type"
                rules = {[{ required: true, message: 'El tipo de dato es requerido' }]} >
                <Select placeholder = "Seleccione el tipo de dato" >
                  <Select.Option value = "Numérico" > Numérico </Select.Option>
                  <Select.Option value = "Alfanumérico" > Alfanumérico </Select.Option>
                  <Select.Option value = "Fecha" > Fecha </Select.Option>
                </Select>
              </Form.Item>
            </Form>
          </Modal> : null }
    </>
  );
};