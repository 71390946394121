/**
 * Componentes DocumentUpload, AccountingUpload, XMLUpload, LinkUpload que permite llevar acabo todas las operaciones de carga de archivos
 */

import React from 'react';
import { useDispatch } from 'react-redux';
import { Upload, notification, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { upload, linking, accounting, xml } from 'actions';

/**
 * Exporta el componente DocumentUpload
 */
export const DocumentUpload = ({ reference, onAddFile }) => {

  // Funcion que permite ejecutar todas las acciones del estado de redux
  const dispatch = useDispatch();

  /**
   * Metodo que se ejecuta cuando cambia el estado de carga del archivo
   * @param {object} info 
   */
  const onChangeUpload = info => {
    switch( info.file.status ) {
      case 'uploading':
        notification.info({
          message: 'Cargando',
          description: `Se esta cargando el archivo ${info.file.name}.`
        })
        break;
      case 'done':
        notification.success({
          message: 'Cargado',
          description: `El archivo ${info.file.name} fue cargado correctamente.`
        })
        break;
      case 'error':
        notification.error({
          message: 'Error',
          description: `Error al cargar el archivo ${info.file.name}.`
        })
        break;
      default:
        break;
    }
  }

  /**
   * Metodo que se ejecuta cuando termina de cargar el archivo
   */
  const onUpload = file => {
    try {
      dispatch( upload( file.file, reference ) ).then( () => {
        file.onSuccess();
        onAddFile();
      }).catch( () => file.onError() )
    } catch ( error ) { file.onError(); }
  };

  /**
   * Retorna el componente
   */
  return (
    <Upload
      name = 'file'
      multiple = { true }
      customRequest = { onUpload }
      showUploadList = { false }
      onChange = { onChangeUpload }
    >
      <Button type = 'dashed' >
        <UploadOutlined /> Subir archivos
      </Button>
    </Upload>
  )

};

/**
 * Exporta el componente AccountingUpload
 */
export const AccountingUpload = ({ reference, onAccounting }) => {

  // Funcion que permite ejecutar todas las acciones del estado de redux
  const dispatch = useDispatch();

  /**
   * Metodo que se ejecuta cuando cambia el estado de carga del archivo
   * @param {object} info 
   */
  const onChangeUpload = info => {
    switch( info.file.status ) {
      case 'uploading':
        notification.info({
          message: 'Cargando',
          description: `Se esta cargando el archivo ${ info.file.name }.`
        })
        break;
      case 'done':
        notification.success({
          message: 'Cargado',
          description: `El archivo ${ info.file.name } fue cargado correctamente.`
        })
        break;
      case 'error':
        notification.error({
          message: 'Error',
          description: `Error al cargar el archivo ${ info.file.name }.`
        })
        break;
      default: break;
    }
  }

  /**
   * Metodo que se ejecuta cuando termina de cargar el archivo
   */
  const onUpload = file => {
    try {
      dispatch( accounting( file.file, reference ) ).then( () => {
        file.onSuccess();
        onAccounting();
      }).catch( () => file.onError() )
    } catch ( error ) { file.onError(); }
  };

  /**
   * Retorna el componente
   */
  return (
    <Upload
      name = 'file'
      multiple = { false }
      customRequest = { onUpload }
      showUploadList = { true }
      onChange = { onChangeUpload }
    >
      <Button type = "primary" >
        <UploadOutlined /> Subir contabilizacion
      </Button>
    </Upload>
  )

};

/**
 * Exporta el componente XMLUpload
 */
export const XMLUpload = ({ reference, onXML }) => {

  // Funcion que permite ejecutar todas las acciones del estado de redux
  const dispatch = useDispatch();

  /**
   * Metodo que se ejecuta cuando cambia el estado de carga del archivo
   * @param {object} info 
   */
  const onChangeUpload = info => {
    switch( info.file.status ) {
      case 'uploading':
        notification.info({
          message: 'Cargando',
          description: `Se esta cargando el archivo ${ info.file.name }.`
        })
        break;
      case 'done':
        notification.success({
          message: 'Cargado',
          description: `El archivo ${ info.file.name } fue cargado correctamente.`
        })
        break;
      case 'error':
        notification.error({
          message: 'Error',
          description: `Error al cargar el archivo ${ info.file.name }.`
        })
        break;
      default: break;
    }
  }

  /**
   * Metodo que se ejecuta cuando termina de cargar el archivo
   */
  const onUpload = file => {
    try {
      dispatch( xml( file.file, reference ) ).then( () => {
        file.onSuccess();
        onXML();
      }).catch( () => file.onError() )
    } catch ( error ) { file.onError(); }
  };

  /**
   * Retorna el componente
   */
  return (
    <Upload
      name = 'file'
      accept = 'text/xml'
      multiple = { false }
      customRequest = { onUpload }
      showUploadList = { true }
      onChange = { onChangeUpload }
    >
      <Button type = "primary" >
        <UploadOutlined /> Subir archivo XML
      </Button>
    </Upload>
  )

};

/**
 * Exporta el componente LinkUpload
 */
export const LinkUpload = ({ reference, onLinking, onPreview, loading }) => {

  // Funcion que permite ejecutar todas las acciones del estado de redux
  const dispatch = useDispatch();

  /**
   * Metodo que se ejecuta cuando cambia el estado de carga del archivo
   * @param {object} info 
   */
  const onChangeUpload = info => {
    switch( info.file.status ) {
      case 'uploading':
        notification.info({
          message: 'Cargando',
          description: `Se esta cargando el archivo ${ info.file.name }.`
        })
        break;
      case 'done':
        notification.success({
          message: 'Cargado',
          description: `El archivo ${ info.file.name } fue cargado correctamente.`
        })
        break;
      case 'error':
        notification.error({
          message: 'Error',
          description: `Error al cargar el archivo ${ info.file.name }.`
        })
        break;
      default: break;
    }
  }

  /**
   * Metodo que se ejecuta cuando termina de cargar el archivo
   */
  const onUpload = file => {
    try {
      onPreview();
      dispatch( linking( file.file, reference ) ).then( () => {
        file.onSuccess();
        onLinking();
      }).catch( () => file.onError() )
    } catch ( error ) { file.onError(); }
  };

  /**
   * Retorna el componente
   */
  return (
    <Upload
      name = 'file'
      multiple = { false }
      disabled = { loading }
      customRequest = { onUpload }
      showUploadList = { false }
      onChange = { onChangeUpload }
    >
      <Button type = "primary" loading = { loading } style = {{ marginBottom: 16 }} >
        <UploadOutlined /> Vincular archivos
      </Button>
    </Upload>
  )

};