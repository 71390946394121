/**
 * Reductor de REDUX para controlar los estados del componente de roles
 */

import { ROLES } from 'types';
import { Map } from 'immutable';

/**
 * Exporta el reductor roles
 * @param {object} state 
 * @param {object} action 
 */
export const roles = ( state = Map({ type: ROLES.GET_ROLES }), action ) => {

  switch ( action.type ) {

    case ROLES.GET_ROLES:
      return state.set('roles', {
        type: action.type
      });
    
    case ROLES.GETTING_ROLES:
      return state.set('roles', {
        type: action.type,
      });
  
    case ROLES.GETTED_ROLES:
      return state.set('roles', {
        type: action.type,
        roles: action.roles
      });

    case ROLES.ERROR:
      return state.set('roles', {
        type: action.type,
        code: action.code,
        message: action.message
      });

    default:
      return state;

  }

}