import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumb, Button, Divider, List, Avatar, notification, Modal, Tag, Input } from 'antd';
import { EditOutlined, LockOutlined, CloseCircleOutlined, CheckCircleOutlined, SwapOutlined, DeleteOutlined, FolderFilled, FileFilled } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import { documents as documentsActions } from 'actions';

import { DocumentUpload } from 'components/documents/upload';
import { useDocuments } from 'components/documents/context';

export const useListFolder = ({ form }) => {

    // Funcion que permite ejecutar todas las acciones del estado de redux
    const dispatch = useDispatch();
    // Selecciona los reductores
    const roles = useSelector(state => state.get('roles').get('roles').roles);
    const auth = useSelector(state => state.get('authenticate').get('login'));
    // Manejo de los estados del componente
    const [folders, setFolders] = useState({ reference: undefined, visible: false, loading: false, name: '', update: false, isLoad: false });
    const [routes, setRoutes] = useState([]);
    const [reference, setReference] = useState();
    const [userRoles, setUserRoles] = useState({ documents: {}, folders: {} });
    const [isClosing, setIsClosing] = useState(false);
    const [isUpload, setIsUpload] = useState(false);
    const [isRootFolder, setIsRootFolder] = useState(true);
    const [isRoot, setIsRoot] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [permissions, setPermissions] = useState({ visible: false, users: [], folder: {} });
    const [data, setData] = useState({ loading: false, columns: [], source: [] });
    const [file, setFile] = useState({ visible: false, isLoad: false, reference: undefined, fields: {} });
    

    // Hook para el manejo de referencias al Dom
    const token = useRef(null);

    /**
     * Metodo que permite actualizar los permisos de un usuario por carpeta
     * @param {object} user 
     */
    const updateUserRoles = user => {
        if ( !isRoot ) {
            if ( user.exists ) {
                const data = user.data();
                setUserRoles({ documents: data.permissions.documents, folders: data.permissions.folders });
                return { documents: data.permissions.documents, folders: data.permissions.folders };
            } else if ( !isAdmin ) {
                setUserRoles({ documents: {}, folders: {} });
                return { documents: {}, folders: {} };
            };
        } else {
            setUserRoles({ documents: roles.documents, folders: roles.folders });
            return { documents: roles.documents, folders: roles.folders };
        };
    };

    /**
     * Metodo que permite cargar la información de una carpeta
     * @param {object} reference 
     */
    const getFolderInfo = reference => {
        return new Promise( ( resolve, reject ) => {

            const ref = reference?.doc ? reference?.parent : reference;
            
            ref.get().then( fields => {
                ref.collection('users').doc( auth.user.uid ).get().then( user => {
                
                    const data = fields.data();
                    const roles = updateUserRoles( user );

                    if ( Array.isArray( data?.folders ) ) {
                        const folder = data.folders.filter( folder => folder.reference.id === reference.id );
                        setIsUpload(folder[0]?.isUpload || false);
                        setIsClosing(folder[0]?.close?.date ? true : false);
                    } else {
                        setIsUpload(false);
                        setIsClosing(false);
                    }

                    resolve(roles);

                }).catch( error => {
                    setIsUpload(false);
                    setIsClosing(false);
                    reject();
                })
            }).catch( error => {
                setIsUpload(false);
                setIsClosing(false);
                reject();
            });

        });
    };

    /**
     * Metodo que permite eliminar una carpeta
     */
    const deleteFolder = ({ folder, roles }) => {
        dispatch(
            documentsActions.delete({
                roles,
                item: Object.assign({}, folder, { token: token.current.state.value }),
                isValidateToken: true
            })
        ).then( result => {
            if ( result?.code !== -99 ) {
                form.resetFields();
                setFolders({ ...folders, isLoad: true });
            } else {
                notification.warn({
                    message: 'Operación no permitida',
                    description: result.message,
                    placement:  'topLeft'
                });
            }
        }).catch( error => {
            notification.error({
                message: 'Error',
                description: 'Se presento un error al tratar de eliminar la carpeta',
                placement:  'topRight'
            })
        });
    };

    /**
     * Metodo que permite cerrar una carpeta
     * @param {object} folder 
     */
    const folderClosing = ({ folder, roles }) => {
        dispatch( documentsActions.close({ roles , item: folder }) ).then( ({ data }) => {
            form.resetFields();
            setFolders({ ...folders, isLoad: true });
        }).catch( error => {
            notification.error({
                message: 'Error',
                description: 'Se presento un error al tratar de cerrar la carpeta',
                placement:  'topRight'
            })
        })
    };

    /**
     * Metodo que permite abrir una carpeta
     * @param {object} folder 
     */
    const folderOpening = ({ folder, roles }) => {
        dispatch( documentsActions.open({ roles, item: folder }) ).then(({ data }) => {
            form.resetFields();
            setFolders({ ...folders, isLoad: true });
        }).catch( error => {
            notification.error({
                message: 'Error',
                description: 'Se presento un error al tratar de cerrar la carpeta',
                placement:  'topRight'
            })
        });
    };

    /**
     * Metodo que permite cargar las carpetas por referencia
     * @param {object} folder 
     */
    const loadFolders = folder => new Promise( ( resolve, reject ) => {
        if (!folder) reject();
        
        setReference(folder);
        setIsRootFolder(folder?.parent?.parent ? false : true);
        setData({ loading: true, columns: [], source: [] });
        getFolderInfo( folder ).then( roles => folder.get().then( async docs => {

            const items = []
            const folderActions = folder => {

                const actions = [];
                const isRootFolder = folder.reference.path?.parent?.parent ? false : true;
                const isClosing = folder?.close?.date ? true : false;

                if ( roles?.folders?.read ) {

                    if ( roles.folders.update && !isClosing ) {
                        if ( isRoot || !isRootFolder ) {
                            actions.push(
                                <Button type = "link" style = {{ paddingLeft: 0 }}
                                    onClick = { () => {
                                        form.resetFields();
                                        form.setFieldsValue({ 
                                            folder: folder.name,
                                            description: folder.description,
                                            isUpload: folder.isUpload
                                        });
                                        setFolders({ reference: folder, visible: true, loading: false, update: true });
                                    }}
                                > <EditOutlined style = {{ marginRight: 8 }} /> Editar carpeta </Button>
                            )
                        }
                    }

                    if ( roles.folders.update ) {
                        actions.push(
                            <Button type = "link" style = {{ paddingLeft: 0 }}
                                onClick = { () => setPermissions({ visible: true, users: permissions.users, folder }) } >
                                <LockOutlined style = {{ marginRight: 8 }} />
                                Editar permisos
                            </Button>
                        )
                    }
        
                    if ( roles.folders.closing ) {
                        if ( isRoot || !isRootFolder ) {
                            if ( !isClosing ) actions.push(
                                <Button type = "link"
                                    style = {{ color: '#FAAD14', paddingLeft: 0 }}
                                    onClick = { () => {
                                        Modal.confirm({
                                            title: `Cerrar carpeta ${ folder.name }`,
                                            content: 'Esta seguro de cerrar la carpeta seleccionada?',
                                            okText: 'Cerrar',
                                            okType: 'warning',
                                            cancelText: 'Cancelar',
                                            onOk: () => folderClosing({ folder, roles: roles.folders })
                                        });
                                    }}
                                > <CloseCircleOutlined style = {{ marginRight: 8 }} /> Cerrar </Button>
                            ); else actions.push(
                                <Button type = "link"
                                    style = {{ color: '#FAAD14', paddingLeft: 0 }}
                                    onClick = { () => {
                                        Modal.confirm({
                                            title: `Abrir carpeta ${ folder.name }`,
                                            content: 'Esta seguro de abrir la carpeta seleccionada?',
                                            okText: 'Abrir',
                                            okType: 'warning',
                                            cancelText: 'Cancelar',
                                            onOk: () => folderOpening({ folder, roles: roles.folders })
                                        });
                                    }}
                                > <CheckCircleOutlined style = {{ marginRight: 8 }} /> Abrir </Button>
                            );
                        }
                    }

                    if ( roles.folders.delete && !isClosing ) {
                        if ( isRoot || !isRootFolder ) {
                            actions.push(
                                <Button type = "link"
                                    style = {{ color: '#F5222D', paddingLeft: 0 }}
                                    onClick = { () => {
                                        Modal.confirm({
                                            title: `Eliminar carpeta ${ folder.name }`,
                                            content: (
                                                <div>
                                                    Ingrese el token para eliminar la carpeta
                                                    <br />
                                                    <Input placeholder = "Token"
                                                        prefix = { <SwapOutlined style = {{ color: 'rgba(0,0,0,.25)' }} /> }
                                                        ref = { token } />
                                                </div>
                                            ),
                                            okText: 'Eliminar',
                                            okType: 'danger',
                                            cancelText: 'Cancelar',
                                            onOk: () => deleteFolder({ folder, roles: roles.folders })
                                        })
                                    }}
                                > <DeleteOutlined style = {{ marginRight: 8 }} /> Eliminar </Button>
                            )
                        }
                    }

                }

                return actions;

            }
        
            if ( docs.data ) {

                if ( Array.isArray( docs.data()?.folders ) ) {
    
                    if ( roles?.folders?.read ) {
                        docs.data().folders.forEach( ( folder, index ) => {
                            items.push({
                                key: uuidv4(),
                                sorted: folder.name,
                                avatar: <FolderFilled />,
                                description: 
                                <>
                                    <p> { folder.description } </p>
                                    { folder?.close?.date ?
                                        <Tag color = 'green'>
                                            Fecha de cierre: {
                                                folder.close.date?.toDate().toLocaleDateString('es-CO', {
                                                weekday: 'long',
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                                })
                                            }
                                        </Tag> : null }
                                </>,
                                actions: folderActions( folder ),
                                title: (
                                    <Button type = "link" style = {{ paddingLeft: 0 }}
                                        onClick = { () => {
                                            setIsUpload(folder.isUpload);
                                            setIsClosing(folder.close ? folder.close.date ? true : false : false);
                                            setRoutes(routes => [...routes, { name: folder.name, reference: folder.reference.path.collection( folder.reference.id ) }]);
                                            loadFolders( folder.reference.path.collection( folder.reference.id ) );
                                        }} 
                                    > <Tag color = "#f50" > { folder.name } </Tag> </Button>
                                )
                                
                            })
                        })

                    }
    
                }

            } else if ( Array.isArray( docs.docs ) ) {
            
                for ( const doc of docs.docs ) {
                    
                    if ( Array.isArray( doc.data()['folders'] ) ) {
    
                        if ( roles?.folders?.read ) {
                            doc.data()['folders'].forEach( ( folder, index ) => {
                                items.push({
                                    key: uuidv4(),
                                    sorted: folder.name,
                                    avatar: <FolderFilled />,
                                    description:
                                        <>
                                            <p>
                                                { folder.description }
                                            </p>
                                            { folder?.close?.date ?
                                                <Tag color = 'volcano'>
                                                    Fecha de cierre: {
                                                    folder.close.date?.toDate().toLocaleDateString('es-CO', {
                                                        weekday: 'long',
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric'
                                                    })
                                                    }
                                                </Tag> : null }
                                        </>,
                                    actions: folderActions( folder ),
                                    title:
                                        <Button type = "link" style = {{ paddingLeft: 0 }}
                                            onClick = { () => {
                                                setIsUpload(folder.isUpload);
                                                setIsClosing(folder.close ? folder.close.date ? true : false : false);
                                                setRoutes(routes => [...routes, { name: folder.name, reference: folder.reference.path.collection( folder.reference.id ) }]);
                                                loadFolders( folder.reference.path.collection( folder.reference.id ) );
                                            }}
                                        > <Tag color = "#f50" > { folder.name } </Tag> </Button>
                                })
                            })

                        }
    
                    } else if ( roles?.documents?.read ) {

                        const fields = await doc.data().reference.get();
                        items.push({
                            key: uuidv4(),
                            sorted: fields.data().name,
                            avatar: <FileFilled />,
                            actions: [],
                            title: (
                                <Button type = "link" style = {{ paddingLeft: 0 }}
                                    onClick = { () => setFile({ visible: true, isLoad: true, reference: fields, fields: fields.data() }) } >
                                    <Tag color = "#f50" > { fields.data().name } </Tag>
                                </Button>
                            ),
                            description: fields.id,
                            data: fields.data()
                        });
                        
                    }
                    
                }

            }

            setData({ loading: false, source: items });
            resolve();
                
        }).catch( error => reject() )).catch( error => reject() );     
    });

    return {
        folders: {
            data: { ...folders },
            set: setFolders,
            load: loadFolders,
            routes: { data: routes, set: setRoutes },
            list: { ...data, set: setData },
            current: {
                reference: { data: reference, set: setReference },
                isClosing: { data: isClosing, set: setIsClosing },
                isUpload: { data: isUpload, set: setIsUpload },
                file: { ...file, set: setFile },
                user: {
                    roles: { ...userRoles, set: setUserRoles },
                    isRootFolder: { data: isRootFolder, set: setIsRootFolder },
                    isRoot: { data: isRoot, set: setIsRoot },
                    isAdmin: { data: isAdmin, set: setIsAdmin },
                    permissions: { ...permissions, set: setPermissions }
                }
            }
        }
    }
};

export const ListFolders = () => {

    // Selecciona los reductores
    const roles = useSelector(state => state.get('roles').get('roles').roles);
    const docs = useSelector(state => state.get('documents').get('read'));

    const { folders, templates, search } = useDocuments();
    const { current } = folders;
    
    /**
     * Metodo que se ejecuta cuando se agrega un archivo a la lista de archivos
     */
    const onAddFile = () => folders.set({ ...folders.data, isLoad: true });

    useEffect(() => {
        if (folders.data.isLoad) {
            folders.set({ ...folders.data, isLoad: false });
            folders.load(folders.current.reference.data);
        }
    }, [folders]);

    return (
        <List
            bordered = { true }
            loading = { folders.list.loading }
            itemLayout = "vertical"
            size = "small"
            pagination = {{ pageSize: 10 }}
            locale = {{ emptyText: 'No hay informacion para esta carpeta' }}
            dataSource = { (folders.list.source || []).sort((a, b) => a?.sorted?.localeCompare(b?.sorted)) }
            renderItem = { item => (
                <List.Item
                    key = { item.key }
                    actions = { item.actions }
                    // extra = { <Avatar icon = { item.avatar } style = {{ verticalAlign: 'middle' }} /> }
                    >
                    <List.Item.Meta title = { item.title } description = { item.description }
                        avatar = { <Avatar style = {{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon = { item.avatar } /> } />
                    { item.content }
                </List.Item>
            )}
            header = { 
                <>
                
                    { ((current.user.isRootFolder.data && roles?.folders?.create) || current.user.roles?.folders?.create) && !current.isClosing.data ?
                        <>
                            <Button type = 'dashed'
                                onClick = { () => folders.set({ ...folders.data, name: "", visible: true, loading: false, update: false }) } >
                                Nueva carpeta
                            </Button>
                            <Divider type = "vertical" />
                            <Button type = 'dashed'
                                onClick = { () => templates.load({ userRoles: current.user.roles }) }
                            > Clonar carpeta </Button>
                        </> : null }

                    { docs?.data && current.isUpload.data && !current.isClosing.data ?
                        <>
                            <Divider type = "vertical" />
                            <DocumentUpload reference = { current.reference.data } onAddFile = { onAddFile } />
                        </> : null }
                    
                    { docs?.data && current.isUpload.data ?
                        <>
                            <Divider type = "vertical" />
                            <Button type = 'dashed'
                                onClick = { () => search.set({ visible: true, loading: false }) }
                            > Buscar archivos </Button>
                        </> : null }

                    { docs?.data ?
                        <div style = {{ marginTop: 15 }} >
                            <Breadcrumb>
                                { folders.routes.data?.map(( route, index ) => (
                                    <Breadcrumb.Item key = { index } >
                                        <Button type = "link" onClick = { () => {
                                            if ( current.reference.data !== route.reference ) {

                                                const index = folders.routes.data.findIndex( item => item.reference === route.reference );
                                                const tmp_routes = folders.routes.data.splice( 0, index + 1 );

                                                folders.load(route.reference);
                                                folders.routes.set(tmp_routes);

                                            }
                                        } } > { route.name } </Button>
                                    </Breadcrumb.Item>
                                )) }
                            </Breadcrumb>
                        </div> : null }
                </>
            } />
    )
}