/**
 * Reductor de REDUX para controlar los estados del componente de Login
 */

import { AUTH } from 'types';
import { Map } from 'immutable';

/**
 * Exporta el reductor authenticate
 * @param {object} state 
 * @param {object} action 
 */
export const authenticate = ( state = Map({ type: AUTH.LOGGINGIN, isLoggedIn: false }), action ) => {

  switch ( action.type ) {

    case AUTH.LOGGINGIN:
      return state.set('login', {
        type: action.type,
        isLoggedIn: false
      });
    
    case AUTH.LOGGEDIN:
      return state.set('login', {
        type: action.type,
        isLoggedIn: true,
        user: action.user
      });
  
    case AUTH.CANCELED:
      return state.set('login', {
        type: action.type,
        isLoggedIn: false
      });

    case AUTH.LOGGINGOUT:
      return state.set('login', {
        type: action.type,
        isLoggedOut: false,
      });

    case AUTH.LOGGEDOUT:
      return state.set('login', {
        type: action.type,
        isLoggedOut: true
      });

    case AUTH.ERROR:
      return state.set('login', {
        type: action.type,
        code: action.code,
        message: action.message
      });

    default:
      return state;

  }

}