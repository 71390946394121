import React from 'react';
import { useDispatch } from 'react-redux';
import { Input, Modal, Form, Checkbox } from 'antd';
import { FolderOutlined } from '@ant-design/icons';

import { documents as documentsActions } from 'actions';
import { useDocuments } from 'components/documents/context';

export const useModalFolder = () => Form.useForm();

export const ModalFolder = ({ form }) => {

    // Funcion que permite ejecutar todas las acciones del estado de redux
    const dispatch = useDispatch();

    const { folders } = useDocuments();
    const { current } = folders;

    /**
     * Metodo que permite crear una carpeta
     */
    const createFolder = values => {
        folders.set({ ...folders.data, name: values.folder, visible: true, loading: true  });
        dispatch(
            documentsActions.create({
                reference: current.reference.data,
                roles: current.user.roles.folders,
                folder: {
                    name: values.folder,
                    description: values.description,
                    isUpload: values.isUpload
                }
            })
        ).then( () => {
            form.resetFields();
            folders.set({ ...folders.data, name: "", visible: false, loading: false, isLoad: true });
        });
    };

    /**
     * Metodo que permite actualizar una carpeta
     */
    const updateFolder = values => {
        folders.set({ ...folders.data, visible: true, loading: true });
        dispatch(
            documentsActions.update({
                item: folders.data.reference,
                roles: current.user.roles.folders,
                values: {
                    name: values.folder,
                    description: values.description,
                    isUpload: values.isUpload
                } 
            })
        ).then( () => {
            form.resetFields();
            folders.set({ ...folders.data, visible: false, loading: false, isLoad: true });
        });
    };

    return folders.data.visible ? (
        <Modal
            forceRender
            visible = { folders.data.visible }
            confirmLoading = { folders.data.loading }
            title = { !folders.data.update ? "Nueva carpeta" : "Editar carpeta" } 
            okText = { !folders.data.update ? "Crear" : "Editar" }
            cancelText = "Cancelar"
            maskClosable = { false }
            closable = { false }
            onOk = { () => form.submit() }
            onCancel = { () => {
                form.resetFields();
                folders.set({ ...folders.data, name: "", visible: false, loading: false });
            }} >
            <Form layout = "vertical" form = { form } onFinish = { !folders.data.update ? createFolder : updateFolder } >
                <Form.Item name = "folder" label = "Carpeta"
                    rules = {[{ required: true, message: 'El nombre de la carpeta es requerido' }]} >
                    <Input
                        placeholder = "Nombre de la carpeta"
                        prefix = { <FolderOutlined style = {{ color: 'rgba(0,0,0,.25)' }} /> } />
                </Form.Item>
                <Form.Item name = "description" label = "Descripcion"
                    rules = {[{ required: true, message: 'La descripcion de la carpeta es requerida' }]} >
                    <Input.TextArea placeholder = "Descripcion de la carpeta" rows = { 4 } />
                </Form.Item>
                <Form.Item name = "isUpload" valuePropName = "checked" initialValue = { false } >
                    <Checkbox> Permitir subir archivos? </Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    ) : false
}