/**
 * Reductor de REDUX para controlar los estados del componente de clientes
 */
import { CUSTOMERS } from 'types';
import { Map } from 'immutable';

/**
 * Exporta el reductor customers
 * @param {object} state 
 * @param {object} action 
 */
export const customers = ( state = Map({ type: CUSTOMERS.READ.READ_CUSTOMERS, customers: [] }), action ) => {

  switch ( action.type ) {

    case CUSTOMERS.CREATE.CREATE_CUSTOMERS:
      return state.set('create', {
        type: action.type,
        customers: []
      });
    
    case CUSTOMERS.CREATE.CREATING_CUSTOMERS:
      return state.set('create', {
        type: action.type,
        customers: []
      });
  
    case CUSTOMERS.CREATE.CREATED_CUSTOMERS:
      return state.set('create', {
        type: action.type,
        customers: action.customers
      });

    case CUSTOMERS.CREATE.ERROR:
      return state.set('create', {
        type: action.type,
        code: action.code,
        message: action.message,
        customers: []
      });
    
    case CUSTOMERS.READ.READ_CUSTOMERS:
      return state.set('read', {
        type: action.type,
        customers: []
      });
    
    case CUSTOMERS.READ.READING_CUSTOMERS:
      return state.set('read', {
        type: action.type,
        customers: []
      });
  
    case CUSTOMERS.READ.READED_CUSTOMERS:
      return state.set('read', {
        type: action.type,
        customers: action.customers
      });

    case CUSTOMERS.READ.ERROR:
      return state.set('read', {
        type: action.type,
        code: action.code,
        message: action.message,
        customers: []
      });

    case CUSTOMERS.UPDATE.UPDATE_CUSTOMERS:
      return state.set('update', {
        type: action.type,
        customers: []
      });
    
    case CUSTOMERS.UPDATE.UPDATING_CUSTOMERS:
      return state.set('update', {
        type: action.type,
        customers: []
      });
  
    case CUSTOMERS.UPDATE.UPDATED_CUSTOMERS:
      return state.set('update', {
        type: action.type,
        customers: action.customers
      });

    case CUSTOMERS.UPDATE.ERROR:
      return state.set('update', {
        type: action.type,
        code: action.code,
        message: action.message,
        customers: []
      });

      case CUSTOMERS.DELETE.DELETE_CUSTOMERS:
      return state.set('delete', {
        type: action.type,
        customers: []
      });
    
    case CUSTOMERS.DELETE.DELETING_CUSTOMERS:
      return state.set('delete', {
        type: action.type,
        customers: []
      });
  
    case CUSTOMERS.DELETE.DELETED_CUSTOMERS:
      return state.set('delete', {
        type: action.type,
        customers: action.customers
      });

    case CUSTOMERS.DELETE.ERROR:
      return state.set('delete', {
        type: action.type,
        code: action.code,
        message: action.message,
        customers: []
      });

    default:
      return state;

  }

}